import styled, { css, keyframes } from 'styled-components/macro';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as ArrowHierarchy } from './arrow_hierarchy.svg';
import { ReactComponent as Burger } from './burger.svg';
import { ReactComponent as BurgerClose } from './burger-close.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as InfoGray } from './info_grey.svg';
import { ReactComponent as LinkArrow } from './arrow-link.svg';
import { ReactComponent as Loader } from './loader.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as TreeDot } from './tree-dot.svg';
import { ReactComponent as TreeToggleMinus } from './tree-toggle-minus.svg';
import { ReactComponent as TreeTogglePlus } from './tree-toggle-plus.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
   }
  to {
    transform: rotate(359deg);
  }
`;

export const IconLoader = styled(Loader)`
  width: 40px;
  height: 40px;
  color: ${props => props.theme.color.dark_blue};
  animation: ${spin} 2s linear infinite;
`;

export const IconBurger = styled(Burger)`
  width: 30px;
  height: 20px;
`;

export const IconBurgerClose = styled(BurgerClose)`
  width: 30px;
  height: 20px;
`;

export const IconInfo = styled(InfoGray)`
  width: 16px;
  height: 16px;

  &:hover #background {
    fill: ${props => props.theme.color.link_blue};
  }
  &:hover #letter-i {
    fill: ${props => props.theme.color.white};
  }
`;

export const IconSearch = styled(Search)`
  width: 20px;
  height: 20px;
`;

export const IconReset = styled(Clear)`
  width: 12px;
  height: 12px;
`;

export const IconClear = styled(Clear)`
  width: 12px;
  height: 12px;
`;

export const IconArrow = styled(Arrow)`
  width: 21px;
  height: auto;
`;

export const IconArrowHierarchy = styled(ArrowHierarchy)`
  width: 13px;
  height: 9.18px;
  min-width: 13px;
  min-height: 9.18px;
`;

export const IconLinkArrow = styled(LinkArrow)`
  width: 8px;
  height: auto;
`;

const treeToggleIconStyles = css`
  width: 10px;
  height: 20px;
`;

export const TreeDotIcon = styled(TreeDot)`
  width: 4px;
  height: 4px;
`;

export const TreeToggleMinusIcon = styled(TreeToggleMinus)`
  ${treeToggleIconStyles};
`;
export const TreeTogglePlusIcon = styled(TreeTogglePlus)`
  ${treeToggleIconStyles};
`;
