import { TreeNode } from '../../api/xtree';
import { NodesMap } from '../../features/Tree';

const ROOT_NODE_ID = 'wk000249';

export class NodeInspector {
  public static getNodePath(node: TreeNode): string[] {
    return [...node.parentIds, node.id];
  }

  public static getRootNode(): string {
    return ROOT_NODE_ID;
  }

  public static isRootNode(id: string): boolean {
    return ROOT_NODE_ID === id;
  }

  public static getAllParentIds(
    id: string,
    nodesMap: NodesMap,
    list: string[] = [],
  ): string[] {
    const node = nodesMap[id];
    if (!node || node.parentIds.length === 0) {
      return list;
    }
    node.parentIds.forEach((pid: string) => {
      if (!list.includes(pid)) {
        list.push(pid);
      }
      NodeInspector.getAllParentIds(pid, nodesMap, list);
    });
    return list;
  }

  public static getAllChildrenIds(
    id: string,
    nodesMap: NodesMap,
    list: string[] = [],
  ) {
    const node = nodesMap[id];
    if (!node || node.childrenIds.length === 0) {
      return list;
    }
    node.childrenIds.forEach((cid: string) => {
      if (!list.includes(cid)) {
        list.push(cid);
      }
      NodeInspector.getAllChildrenIds(cid, nodesMap, list);
    });
    return list;
  }
}
