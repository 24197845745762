import { createSlice } from '../../../utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { useInjectReducer } from '../../../utils/redux-injectors';
import { MobileMenuState } from './types';

export const initialState: MobileMenuState = {
  isOpen: false,
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const mobileMenuSlice = createSlice({
  name: 'mobileMenu',
  initialState,
  reducers: {
    toggleMobileMenu(state) {
      state.isOpen = !state.isOpen;
    },
    closeMobileMenu(state) {
      state.isOpen = false;
    },
  },
});

// Exports
export const { actions } = mobileMenuSlice;
export const useMobileMenuSliceSlice = () => {
  useInjectReducer({
    key: mobileMenuSlice.name,
    reducer: mobileMenuSlice.reducer,
  });
  return { actions: mobileMenuSlice.actions };
};
