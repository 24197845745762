import { XTreeMapItem } from '../normalizer';
import { MapItem } from './MapItem';

export class MapItems {
  protected _items: MapItem[] = [];

  public constructor(items: XTreeMapItem[] = []) {
    this._items = items
      .filter((item: any) =>
        [
          'broadMatch',
          'closeMatch',
          'exactMatch',
          'relatedMatch',
          'narrowMatch',
          'mappingRelation',
        ].includes(item.mappingRelation),
      )
      .map(item => new MapItem(item));
  }

  get items(): MapItem[] {
    return this._items;
  }
}
