/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';

import { HelmetProvider } from 'react-helmet-async';

// Use consistent styling
import 'sanitize.css/sanitize.css';

import { configureAppStore } from 'store/configureStore';

// Initialize languages
import './locales/i18n';

import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/history';
import App from './app';

// lazy load of root app
// const App = React.lazy(() => import('./app'));

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files and reducers
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });

  if (process.env.NODE_ENV === 'development') {
    module.hot.accept('./store/reducers', () => {
      const newRootReducer = require('./store/reducers').default;
      store.replaceReducer(newRootReducer);
    });
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
