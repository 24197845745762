import { XTreeNote } from '../normalizer';

export interface Note {
  lang: string;
  noteType: string;
  source: string;
  value: string;
}

export class Notes {
  protected _notes: Note[] = [];
  protected _definitions: Note[] | null = null;
  protected _scopeNotes: Note[] | null = null;
  protected _explanations: Note[] | null = null;

  public constructor(notes: XTreeNote[] = []) {
    this._notes = notes.map(note => {
      const noteType = this.resolveNoteType(note);
      const value = note[noteType];
      let clone = { ...note, noteType, value };
      if (clone[noteType]) {
        delete clone[noteType];
      }
      return clone as Note;
    });
  }

  protected resolveNoteType(note): string {
    if (note.changeNote) return 'changeNote';
    if (note.definition) return 'definition';
    if (note.editorialNote) return 'editorialNote';
    if (note.etmology) return 'etmology';
    if (note.example) return 'example';
    if (note.explanation) return 'explanation';
    if (note.extDefinition) return 'extDefinition';
    if (note.glossaryEntry) return 'glossaryEntry';
    if (note.historyNote) return 'historyNote';
    if (note.miscNote) return 'miscNote';
    if (note.note) return 'note';
    if (note.scopeNote) return 'scopeNote';
    return 'unknownProperty';
  }

  protected filterByType(noteType: string) {
    return this._notes.filter(note => note.noteType === noteType);
  }

  get hasDefinitions() {
    return this.definitions.length > 0;
  }
  get definitions(): Note[] {
    if (!this._definitions) {
      this._definitions = this.filterByType('definition');
    }
    return this._definitions as Note[];
  }

  get hasScopeNotes() {
    return this.scopeNotes.length > 0;
  }

  get hasExplanationNotes() {
    return this.explanations.length > 0;
  }
  get explanations() {
    if (!this._explanations) {
      this._explanations = this.filterByType('explanation');
    }
    return this._explanations as Note[];
  }

  get scopeNotes(): Note[] {
    if (!this._scopeNotes) {
      this._scopeNotes = this.filterByType('scopeNote');
    }
    return this._scopeNotes as Note[];
  }
}
