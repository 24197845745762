import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectSearch = (state: RootState) => state.search || initialState;

export const selectSearchIsLoading = createSelector(
  [selectSearch],
  substate => substate.isLoading,
);

export const selectSearchRequest = createSelector(
  [selectSearch],
  substate => substate.request,
);

export const selectResultList = createSelector([selectSearch], substate => {
  return substate.data.idsList.filter((id: string) => {
    return (
      id.startsWith('wk') &&
      substate.data.mapById[id].vocabularyType !== 'ConceptGroup'
    );
  });
});

export const selectResultById = (id: string) =>
  createSelector([selectSearch], substate => substate.data.mapById[id]);

export const selectNormalizedData = createSelector([selectSearch], substate => {
  return substate.response?.normalizedData || undefined;
});

export const selectSearchResponse = createSelector(
  [selectSearch],
  substate => substate.response,
);
