import styled, { css } from 'styled-components/macro';
import { font_Header, media } from '../../../styles';
import { LI, UL } from '../HTML';
import { RouterLinkWhite } from '../Link';

interface NavProps {
  $visible: boolean;
}
export const Wrapper = styled.div<NavProps>`
  ${media.mobile`
    ${props =>
      props.$visible
        ? css`
            background-color: ${props => props.theme.color.dark_blue};
            margin: 0 auto;
            padding: ${props => props.theme.pagePadding.mobile}rem;
            position: absolute;
            top: 103px;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            display: block;
            z-index: 1;
          `
        : css`
            width: 0;
            height: 0;
          `};
  `};
  ${media.tablet_portrait_up`
    grid-area: main-navigation;
    display: block;
  `};
`;

export const CloseButtonContainer = styled.div`
  > button {
    margin-left: auto;
  }
`;

export const Nav = styled.nav`
  ${media.desktop_up`
    margin-right: ${props => props.theme.grid.gutter.desktop}rem;
  `};
`;

export const HeaderMenuList = styled(UL)<NavProps>`
  margin-top: ${props => props.theme.spacing._68}rem;
  ${media.tablet_portrait_up`
    display: flex;
    margin-top: 0;
  `};
  ${props =>
    !props.$visible &&
    media.mobile`
    display: none;
  `};
`;

export const HeaderMenuListItem = styled(LI)`
  margin-bottom: ${props => props.theme.spacing._32}rem;
  text-align: center;

  ${media.tablet_portrait_up`
    margin-bottom: 0;
    margin-right: ${props => props.theme.spacing._28}rem;
  `};
`;

interface HeaderMenuLinkProps {
  $active: boolean;
}
export const HeaderMenuLink = styled(RouterLinkWhite)<HeaderMenuLinkProps>`
  ${font_Header};
  ${props =>
    props.$active &&
    css`
      color: ${props => props.theme.color.white};
      text-decoration: underline;
    `}
`;
