import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import de from './de/translation.json';
import deRoles from './de/roles.json';
import deHelmet from './de/helmet.json';
// import en from './en/translation.json';
// import enRoles from './en/roles.json';
import { convertLanguageJsonToObject } from './translations';

export const DEFAULT_LANGUAGE = 'de';
export const SUPPORTED_LANGUAGES: string[] = [DEFAULT_LANGUAGE];

const dateTranslations = { formattedIntlDate: '{{ date, intlDate }}' };
export const translationsJson = {
  de: {
    translation: { ...de, ...dateTranslations },
    roles: deRoles,
    helmet: deHelmet,
  },
  // en: {
  //   // translation: { ...enRoles, ...dateTranslations },
  //   roles: enRoles,
  //   helmet: deHelmet, // DE
  // },
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(de);

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: DEFAULT_LANGUAGE, // overrides language detection
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    load: 'languageOnly',
    resources: translationsJson,
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === 'intlDate') {
          return new Intl.DateTimeFormat(lng, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(value);
        }
        return value;
      },
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    keySeparator: false, // use false for a flat json; default value is '.'
  });
