import * as React from 'react';
import { useTranslation } from 'react-i18next';
import menusContent from '../../../content/menus.json';
import { MenuItem } from '../../../types';
import { LogoDigicult, LogoCC } from '../Icon/logos';
import { messages } from './messages';
import {
  Footer,
  FooterMenuList,
  FooterMenuListItem,
  FooterMenuLink,
  FooterTop,
  FooterBottom,
} from './style';

export function PageFooter() {
  const { t } = useTranslation();
  const menuItems: MenuItem[] = menusContent.footer;

  return (
    <Footer id="page-footer">
      <FooterTop>
        <FooterMenuList>
          {menuItems.map((item, idx) => (
            <FooterMenuListItem key={idx}>
              {typeof item === 'string' ? (
                <span>{t(messages[item])}</span>
              ) : (
                <FooterMenuLink to={{ pathname: item.url }}>
                  {t(messages[item.label])}
                </FooterMenuLink>
              )}
            </FooterMenuListItem>
          ))}
          <FooterMenuListItem>
            <a href={t(messages.ccLogoUrl)} target="_blank" rel="noreferrer">
              <LogoCC title={t(messages.ccLogoAlt)} />
            </a>
          </FooterMenuListItem>
        </FooterMenuList>
      </FooterTop>
      <FooterBottom>
        <LogoDigicult
          aria-labelledby={'title'}
          title={t('Logo.Digicult.tooltip')}
          aria-hidden={true}
        />
      </FooterBottom>
    </Footer>
  );
}
