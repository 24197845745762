import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';
import { ContentViewType } from './types';

// First select the relevant part from the state
const selectContentViewSwitcher = (state: RootState) =>
  state.contentViewSwitcher || initialState;

export const selectCurrentView = createSelector(
  [selectContentViewSwitcher],
  substate => substate.view,
);

export const selectIsDetailsView = createSelector(
  [selectContentViewSwitcher],
  substate => substate.view === ContentViewType.Details,
);

export const selectIsSearchResultsView = createSelector(
  [selectContentViewSwitcher],
  substate => substate.view === ContentViewType.SearchResults,
);

export const selectIsTreeView = createSelector(
  [selectContentViewSwitcher],
  substate => substate.view === ContentViewType.Tree,
);
