import { ResourceRepresentation } from '../normalizer';

export interface ImageLicense {
  url: string;
  holder?: string;
}

export class VocabImage {
  protected _data: ResourceRepresentation;

  public constructor(data: ResourceRepresentation) {
    this._data = data;
  }

  get imageUrl() {
    return this._data.providedImage;
  }

  get thumbnailUrl() {
    return this._data.previewImage;
  }

  get hasImageUrl() {
    return this.imageUrl || this.thumbnailUrl;
  }

  get altText() {
    if (!this._data.description || !this._data.description.length) return '';
    return (this._data.description[0].de ?? '').trim();
  }

  get title() {
    if (!this._data.title || !this._data.title.length) return '';
    return (this._data.title[0].de ?? '').trim();
  }

  get source() {
    return (this._data.source ?? '').trim();
  }

  get photographer() {
    return (this._data.photographer ?? '').trim();
  }

  get copyrights() {
    return (this._data.rightsHolder ?? '').trim();
  }

  get licenseUrl() {
    return (this._data.rightsType ?? '').trim();
  }
}
