import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMobileMenuSliceSlice } from '../../features/MobileMenu';
import { Routes } from '../../routes';
import { MobileMenuButton } from '../Button/MobileMenuButton';
import { LogoLVR, LogoWNK } from '../Icon/logos';
import { SkipToMainContent } from '../Link/SkipToMainContent';
import { MainNavigation } from '../MainNavigation';
import { SearchBar } from '../SearchBar';
import {
  BlueBackground,
  Header,
  LvrLogoWrapper,
  LogoLinkWrapper,
  LogosBanner,
  MenuAndSearchBanner,
  BurgerButtonContainer,
} from './style';

export function PageHeader() {
  useMobileMenuSliceSlice();
  const { t } = useTranslation();

  return (
    <Header id="page-header">
      <SkipToMainContent />
      <LogosBanner>
        <LogoLinkWrapper
          aria-label={t('Logo.WNK.ariaLabel')}
          to={{ pathname: Routes.Home }}
        >
          <LogoWNK
            title={t('Logo.WNK.tooltip')}
            role="img"
            aria-hidden={true}
            aria-labelledby={'title'}
          />
        </LogoLinkWrapper>
        <LvrLogoWrapper>
          <LogoLVR
            title={t('Logo.LVR.tooltip')}
            role="img"
            aria-hidden={true}
            aria-labelledby={'title'}
          />
        </LvrLogoWrapper>
      </LogosBanner>
      <BlueBackground>
        <BurgerButtonContainer>
          <MobileMenuButton />
        </BurgerButtonContainer>
        <MenuAndSearchBanner>
          <MainNavigation />
          <SearchBar />
        </MenuAndSearchBanner>
      </BlueBackground>
    </Header>
  );
}
