import { _t } from '../../../utils/messages';

const scope = 'SearchBar';

export const messages = {
  placeholder: _t(`${scope}.placeholder`),
  placeholderAriaLabel: _t(`${scope}.placeholder.ariaLabel`),
  resetInputAriaLabel: _t(`${scope}.button.resetSearchTerm.ariaLabel`),
  resetInputTooltip: _t(`${scope}.button.resetSearchTerm.tooltip`),
  searchAriaLabel: _t(`${scope}.button.search.ariaLabel`),
  searchTooltip: _t(`${scope}.button.search.tooltip`),
};
