import styled from 'styled-components/macro';
import { media } from '../../../../styles';
import { ReactComponent as DigicultLogo } from './digicult-logo.svg';
import { ReactComponent as CCLogo } from './cc-logo.svg';
import { ReactComponent as LvrLogo } from './lvr-logo.svg';
import { ReactComponent as WnkLogo } from './wnk-logo.svg';

export const LogoDigicult = styled(DigicultLogo)`
  width: 140px;
  height: 26px;

  ${media.desktop_up`
    width: 159.19px;
    height: 29.9px;
  `};
`;

export const LogoLVR = styled(LvrLogo)`
  display: block;
  width: 98px;
  height: auto;
  ${media.tablet_portrait_up`
     width: 136.75px;
     height: 55.23px;
  `};
`;

export const LogoWNK = styled(WnkLogo)`
  width: 160px;
  height: auto;

  ${media.tablet_portrait_up`
    width: 241px;
    height: 70.73px;
  `};
`;

export const LogoCC = styled(CCLogo)`
  width: 32px;
  height: 32px;
`;
