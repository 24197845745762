import styled, { css } from 'styled-components/macro';
import { media } from '../../../styles';
import { pageContentLayout } from '../PageLayout/style';
import { Grid } from '../Grid';
import { RouterLink } from '../Link';

export const Header = styled.header`
  grid-area: page--header;
`;

export const LogosBanner = styled.div`
  ${pageContentLayout};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${props => props.theme.spacing._24}rem;
  padding-bottom: ${props => props.theme.spacing._24}rem;
`;
export const LogoLinkWrapper = styled(RouterLink)``;

export const LvrLogoWrapper = styled.div`
  justify-self: end;
  > .mobile-only {
    ${media.tablet_portrait_up`
      display: none;
    `}
  }
  > .desktop-only {
    display: none;
    ${media.tablet_portrait_up`
      display: block;
    `}
  }
`;

export const BlueBackground = styled.div`
  background-color: ${props => props.theme.color.dark_blue};
`;

const gridStyles = css`
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto;
  -ms-grid-rows: auto;
  grid-row-gap: 0;

  grid-template-areas: 'search-input';
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-column-gap: 0;

  ${media.tablet_portrait_up`
    grid-row-gap: ${props => props.theme.spacing._18}rem;
    grid-template-areas:
      'main-navigation'
      'search-input';
  `};

  ${media.desktop_up`
    grid-row-gap: 0;
    grid-template-areas: 'main-navigation search-input';
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 0 1fr;
    align-items: center;
  `};
`;

export const MenuAndSearchBanner = styled.div`
  ${pageContentLayout};
  ${gridStyles};

  padding-top: ${props => props.theme.spacing._31}rem;
  padding-bottom: ${props => props.theme.spacing._42}rem;

  ${media.tablet_portrait_up`
    padding-top: ${props => props.theme.spacing._50}rem;
    padding-bottom: ${props => props.theme.spacing._50}rem;
  `};

  ${media.desktop_up`
    padding-top: ${props => props.theme.spacing._66}rem;
    padding-bottom: ${props => props.theme.spacing._70}rem;
  `};
`;

export const BurgerButtonContainer = styled(Grid)`
  ${pageContentLayout};
  ${gridStyles};

  padding-top: ${props => props.theme.spacing._20}rem;
  ${media.tablet_portrait_up`
    display: none;
  `};
`;
