import { SearchResponse } from '../features/Search';
import { XTreeJsonResponse, NormalizerResponse } from '../model/normalizer';

export function buildSearchResult(
  json: XTreeJsonResponse,
  normalizedData: NormalizerResponse,
) {
  return {
    apiResponse: json,
    normalizedData,
  } as SearchResponse;
}
