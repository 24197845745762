export function findFirstPathPerParentId(
  id: string,
  paths: string[],
  parentIds: string[],
): string[] {
  if (!parentIds.length) {
    return [];
  }

  let idsToFind: string[] = [...parentIds];
  const firstPathsPerParentId: string[] = [];

  let regex = getRegexIdAndParentIdPath(id, idsToFind);
  for (let i = 0; i < paths.length && idsToFind.length > 0; i++) {
    const path = paths[i];
    if (regex.test(path)) {
      firstPathsPerParentId.push(path);
      const parentIdFound = paths[i].split(';')[2];
      removeIdFromMutableList(parentIdFound, idsToFind);
      regex = getRegexIdAndParentIdPath(id, idsToFind);
    }
  }
  return firstPathsPerParentId;
}

function getRegexIdAndParentIdPath(id: string, parentIds: string[]): RegExp {
  return new RegExp(`^;${id};(${parentIds.join('|')})`);
}
function removeIdFromMutableList(id: string, list: string[]): void {
  list.splice(list.indexOf(id), 1);
}
