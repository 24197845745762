import { NodesMap } from '../../features/Tree';
import { NodeInspector } from '../tree/NodeInspector';
import { VocabTypes } from '../normalizer';

export enum HierarchyRelation {
  Generic = 'btg_ntg',
  Partitive = 'btp_ntp',
}

export function getNodeLabelIdsInHierarchy(
  hierarchy: string[],
  nodesMap: NodesMap,
): string[] {
  return hierarchy.flatMap((path: string) => {
    return path
      .split(';')
      .filter(
        (id: string) =>
          !!id && nodesMap[id].entityType === VocabTypes.ThesaurusArray,
      );
  });
}

export function getHierarchyIdsByRelationType(
  list,
  type: HierarchyRelation,
  idsWithoutRelationType: string[],
): string[] {
  if (!list) return [];

  function hasTypeOfHierarchicalRelation(item) {
    return (
      item.hasOwnProperty('typeOfHierarchicalRelation') &&
      item.typeOfHierarchicalRelation === type
    );
  }
  return list
    .filter(
      item =>
        hasTypeOfHierarchicalRelation(item) ||
        idsWithoutRelationType.includes(item.id),
    )
    .map(item => item.id);
}

export function filterHierarchyIds(id, hierarchy, list): string[] {
  if (!list.length) return [];
  const regex = new RegExp(`^;${id};(${list.join('|')})`);
  return hierarchy.filter(path => regex.test(path));
}

export function hierarchyStrToArray(paths: string[]): string[][] {
  return paths
    .map((pathAsStr: string) =>
      pathAsStr
        .split(';')
        // exclude root node from hierarchy
        .filter(item => !!item && !NodeInspector.isRootNode(item))
        .reverse(),
    )
    .filter(item => item.length > 1);
}

export function createHierarchy(
  id: string,
  nodesMap: NodesMap,
): string[] | null {
  const hierarchies = buildPathToRoot(id, nodesMap, '');
  if (hierarchies) {
    // filter only those which end in the root node
    return hierarchies.filter(ids => {
      const idsList = ids.split(';');
      return NodeInspector.isRootNode(idsList[idsList.length - 1]);
    });
  }
  return hierarchies;
}

function buildPathToRoot(
  id: string,
  nodesMap: NodesMap,
  basePath: string,
): string[] | null {
  if (!nodesMap.hasOwnProperty(id)) {
    return null;
  }

  const path = [basePath, id].join(';');
  if (nodesMap[id].parentIds.length === 0) {
    return [path];
  }

  return nodesMap[id].parentIds.flatMap(
    pid => buildPathToRoot(pid, nodesMap, path) || [],
  );
}
