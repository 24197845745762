import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from './styles';
import { messages } from './messages';

export function SkipToMainContent() {
  const { t } = useTranslation();
  return (
    <>
      <Link href="#main-content">{t(messages.linkMainText)}</Link>
      <Link href="#search-input">{t(messages.linkSearchText)}</Link>
    </>
  );
}
