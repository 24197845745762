import styled from 'styled-components/macro';
import { media, font_Footer } from '../../../styles';
import { LI, UL } from '../HTML';
import { RouterLinkWhite } from '../Link';
import { pageContentLayout } from '../PageLayout/style';

export const Footer = styled.footer`
  grid-area: page--footer;
`;

export const FooterTop = styled.div`
  background-color: ${props => props.theme.color.dark_blue};
  padding-top: ${props => props.theme.spacing._18}rem;
  padding-bottom: ${props => props.theme.spacing._18}rem;
`;

export const FooterMenuList = styled(UL)`
  ${pageContentLayout};
  @media only screen and (min-width: 910px) {
    display: flex;
    align-items: center;
  }
`;

export const FooterMenuListItem = styled(LI)`
  > * {
    ${font_Footer};
  }
  margin-bottom: ${props => props.theme.spacing._11}rem;

  @media only screen and (min-width: 910px) {
    display: flex;
    margin-bottom: 0;
    margin-right: ${props => props.theme.spacing._30}rem;
  }
`;

export const FooterMenuLink = styled(RouterLinkWhite)``;

export const FooterBottom = styled.div`
  ${pageContentLayout};
  padding-top: ${props => props.theme.spacing._28}rem;
  padding-bottom: ${props => props.theme.spacing._28}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile_tiny`
    > * {
      max-width: 40%;
    }
  `};
  > :nth-child(2) {
    ${media.tablet_portrait_up`
      display: none;
    `};
  }
`;
