import { createSlice } from '../../../utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { useInjectReducer } from '../../../utils/redux-injectors';
import { ContentViewType, ContentViewSwitcherState } from './types';

export const initialState: ContentViewSwitcherState = {
  view: ContentViewType.Tree,
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const contentViewSwitcherSlice = createSlice({
  name: 'contentViewSwitcher',
  initialState,
  reducers: {
    viewDetails(state) {
      state.view = ContentViewType.Details;
    },
    viewSearchResults(state) {
      state.view = ContentViewType.SearchResults;
    },
    viewTree(state) {
      state.view = ContentViewType.Tree;
    },
  },
});

// Exports
export const { actions } = contentViewSwitcherSlice;
export const useContentViewSwitcherSlice = () => {
  useInjectReducer({
    key: contentViewSwitcherSlice.name,
    reducer: contentViewSwitcherSlice.reducer,
  });
  return { actions: contentViewSwitcherSlice.actions };
};
