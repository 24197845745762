import { NormalizerEntities, VocabType } from '../normalizer';
import { VocabularyItemFactory } from './VocabularyItemFactory';
import { VocabularyItem } from './VocabularyItem';

export class RelatedVocabItems {
  protected _itemIds: Record<string, string>[];
  protected _items: VocabularyItem[] = [];

  public constructor(items: Record<string, string>[] = []) {
    this._itemIds = items;
  }

  public init(entities: NormalizerEntities) {
    this._items = this._itemIds
      .map(item => {
        const type: VocabType = Object.keys(item)[0] as VocabType;
        const id = item[type];
        return VocabularyItemFactory.create(entities[type][id], type, entities);
      })
      .sort((a: VocabularyItem, b: VocabularyItem) =>
        this.compareTerms(a.preferredTerm, b.preferredTerm),
      );
  }

  protected compareTerms(termA: string, termB: string): number {
    if (termA < termB) {
      return -1;
    }
    if (termA > termB) {
      return 1;
    }
    return 0;
  }

  get items(): VocabularyItem[] {
    return this._items;
  }

  get hasItems(): boolean {
    return this.items.length > 0;
  }
}
