import styled, { css } from 'styled-components/macro';
import { media } from '../../../styles';

export const PageGrid = styled.div`
  min-height: 100vh;

  display: -ms-grid;
  display: grid;

  grid-template-rows: auto 1fr auto;
  -ms-grid-rows: auto 1fr auto;

  grid-column-gap: 0;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  position: relative;
  grid-template-areas:
    'page--header'
    'page--main'
    'page--footer';
`;

export const PageMain = styled.main`
  grid-area: page--main;
`;

export const pageContentPadding = css`
  padding-left: ${props => props.theme.pagePadding.mobile}rem;
  padding-right: ${props => props.theme.pagePadding.mobile}rem;

  ${media.tablet_portrait_up`
    padding-left: ${props => props.theme.pagePadding.tablet_portrait}rem;
    padding-right: ${props => props.theme.pagePadding.tablet_portrait}rem;
  `};

  ${media.desktop_up`
    padding-left: ${props => props.theme.pagePadding.desktop}rem;
    padding-right: ${props => props.theme.pagePadding.desktop}rem;
  `};
`;

export const pageContentLayout = css`
  margin-right: auto;
  margin-left: auto;
  ${pageContentPadding};

  ${media.desktop_up`
    max-width: ${(props: any) => props.theme.breakpoints.desktop}px;
  `};
`;

export const leftContent = css`
  ${pageContentPadding};
  margin-left: auto;
  max-width: calc(1280px / 2);
`;

export const rightContent = css`
  ${pageContentPadding};
  margin-right: auto;
  max-width: calc(1280px / 2);
`;
