import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import menusContent from '../../../content/menus.json';
import { HiddenH2 } from '../HTML';
import { Link } from '../../../types';
import { actions as contentViewActions } from '../../features/ContentViewSwitcher';
import { actions, selectIsMobileMenuOpen } from '../../features/MobileMenu';
import { actions as treeActions } from '../../features/Tree';
import { MobileMenuButton } from '../Button/MobileMenuButton';
import { messages } from './messages';
import {
  CloseButtonContainer,
  HeaderMenuLink,
  HeaderMenuList,
  HeaderMenuListItem,
  Nav,
  Wrapper,
} from './style';

export function MainNavigation() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileMenuOpen: boolean = useSelector(selectIsMobileMenuOpen);
  const menuItems: Link[] = menusContent.main;
  const { pathname } = useLocation();
  const { id } = useParams();

  const handleClick = useCallback(() => {
    if (!isMobileMenuOpen) return;
    dispatch(contentViewActions.viewTree());
    dispatch(treeActions.resetSelectedId());
    dispatch(actions.toggleMobileMenu());
  }, [dispatch, isMobileMenuOpen]);

  return (
    <Wrapper $visible={isMobileMenuOpen}>
      {isMobileMenuOpen && (
        <CloseButtonContainer>
          <MobileMenuButton />
        </CloseButtonContainer>
      )}
      <HiddenH2
        id="main-navigation-label"
        aria-label={t('Menu.MainNavigation.H2.ariaLabel')}
      />
      <Nav id="main-navigation" aria-labelledby="main-navigation-label">
        <HeaderMenuList $visible={isMobileMenuOpen}>
          {menuItems.map((item: Link) => (
            <HeaderMenuListItem key={item.label}>
              <HeaderMenuLink
                to={{ pathname: item.url }}
                $active={
                  item.url === pathname ||
                  (item.label === 'browseTheViewer' && !!id)
                }
                onClick={handleClick}
              >
                {t(messages[item.label])}
              </HeaderMenuLink>
            </HeaderMenuListItem>
          ))}
        </HeaderMenuList>
      </Nav>
    </Wrapper>
  );
}
