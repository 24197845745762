import { css } from 'styled-components/macro';
import { defaultTheme } from './defaultTheme';

export const fontFaces = css`
  @font-face {
    font-family: 'soehne';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.light};
    font-display: swap;
    src: url('/fonts/soehne-web-leicht.woff2') format('woff2'),
      url('/fonts/soehne-web-leicht.woff') format('woff');
  }

  @font-face {
    font-family: 'soehne';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.light};
    font-display: swap;
    src: url('/fonts/soehne-web-leicht-kursiv.woff2') format('woff2'),
      url('/fonts/soehne-web-leicht-kursiv.woff') format('woff');
  }

  @font-face {
    font-family: 'soehne';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.regular};
    font-display: swap;
    src: url('/fonts/soehne-web-buch.woff2') format('woff2'),
      url('/fonts/soehne-web-buch.woff') format('woff');
  }

  @font-face {
    font-family: 'soehne';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.regular};
    font-display: swap;
    src: url('/fonts/soehne-web-buch-kursiv.woff2') format('woff2'),
      url('/fonts/soehne-web-buch-kursiv.woff') format('woff');
  }

  @font-face {
    font-family: 'soehne';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.bold};
    font-display: swap;
    src: url('/fonts/soehne-web-kraftig.woff2') format('woff2'),
      url('/fonts/soehne-web-kraftig.woff') format('woff');
  }

  @font-face {
    font-family: 'soehne';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.bold};
    font-display: swap;
    src: url('/fonts/soehne-web-kraftig-kursiv.woff2') format('woff2'),
      url('/fonts/soehne-web-kraftig-kursiv.woff') format('woff');
  }
`;
