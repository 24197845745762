import styled from 'styled-components/macro';
import { media } from '../../../../styles';
import { IconButton } from '../IconButton';

export const Button = styled(IconButton)`
  justify-self: end;
  cursor: pointer;
  color: white;

  ${media.tablet_portrait_up`
    display: none;
  `};
`;
