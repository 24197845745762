import { Routes } from './types';

export class RouteUtils {
  public static isSearchPage(location) {
    return location.pathname === Routes.Search;
  }

  public static isDetailsPage(location) {
    return location.pathname.startsWith('/wnk');
  }

  public static getSearchTermLink(term: string) {
    return `${Routes.Search}?term=${term.trim()}`;
  }
}
