import styled, { css } from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { font_Link } from '../../../styles';

export const linkInteractionStyles = css`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.link_blue};
    text-decoration: underline;
  }
`;

export const Link = styled.a`
  ${font_Link};
  ${linkInteractionStyles};
`;

export const RouterLink = styled(ReactRouterLink)`
  ${linkInteractionStyles};
`;

export const linkWhiteStyles = css`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.color.white};
  &:hover {
    color: ${props => props.theme.color.white};
    text-decoration: underline;
  }
`;

export const RouterLinkWhite = styled(ReactRouterLink)`
  ${linkWhiteStyles};
`;
