import { call, select } from 'redux-saga/effects';
import { request } from '../../../utils/request';
import {
  selectXTreeApiFormLoginId,
  selectXTreeApiFormLoginName,
  selectXTreeApiPassword,
  selectXTreeApiUsername,
} from '../../features/Config';
import { buildRequestUrl } from '../buildRequestUrl';
import { ENDPOINT } from '../constants';

export function* authenticatedRequest(url: string, options?: RequestInit) {
  let invalidCookie = false;
  try {
    return yield call(request, url, options);
  } catch (err) {
    invalidCookie = err.error === 'cookie invalid';
  }

  if (invalidCookie) {
    try {
      yield call(login);
      // console.log('Login successful. Retrying request');
      return yield call(request, url, options);
    } catch (e) {
      console.log('ERROR 1', e);
      return { error: e.error };
    }
  }

  return {};
}

export function* login() {
  try {
    const username = yield select(selectXTreeApiUsername);
    const password = yield select(selectXTreeApiPassword);
    const submit = yield select(selectXTreeApiFormLoginName);
    const submitId = yield select(selectXTreeApiFormLoginId);

    const url = yield call(buildRequestUrl, ENDPOINT.Login);
    const body = {
      userName: username,
      password: password,
      submit: submit,
      submitID: submitId,
    };
    const bodyForm = Object.keys(body).map(key => `${key}=${body[key]}`);
    yield call(request, url, {
      method: 'POST',
      headers: {
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: bodyForm.join('&'),
    });
  } catch (e) {
    console.log('error calling POST /login', e);
    throw e;
  }
}
