import {
  NormalizerEntities,
  VocabType,
  XTreeVocabularyItem,
} from '../normalizer';
import { Concept } from './Concept';
import { VocabularyItem } from './VocabularyItem';

export class VocabularyItemFactory {
  public static create(
    item: XTreeVocabularyItem,
    type: VocabType,
    entities: NormalizerEntities,
  ): VocabularyItem {
    return VocabularyItem.create(Concept as any, type, item, entities);
  }
}
