import { takeLatest, call, put } from 'redux-saga/effects';
import xTreeApi, {
  buildSearchResult,
  ErrorBuilder,
  errorHandler,
} from '../../../api';
import { XTreeParams } from '../../../api/xtree';
import {
  normalizeData,
  NormalizerResponse,
  XTreeJsonResponse,
} from '../../../model/normalizer';
import { actions as viewSwitcherActions } from '../../ContentViewSwitcher/slice';
import { actions } from '../slice';
import { SearchResponse } from '../types';

export function* searchSaga() {
  yield takeLatest(actions.search.type, doSearch);
}

export function* doSearch(action) {
  let response: XTreeJsonResponse | null = null;
  const isTermSearch = !action.payload.id;

  try {
    if (isTermSearch) {
      const { term } = action.payload;
      response = yield call(xTreeApi.getSearchVocItemsByTerm, term);
    } else {
      const { id } = action.payload;
      response = yield call(
        xTreeApi.getSearchVocItemsById,
        [id],
        XTreeParams.JsonFull.custom,
      );
    }

    const normalizedData: NormalizerResponse | null = normalizeData(response);

    const searchResult: SearchResponse = buildSearchResult(
      response as XTreeJsonResponse,
      normalizedData,
    );

    if (isTermSearch) {
      yield put(viewSwitcherActions.viewSearchResults());
    }
    yield put(actions.searchSuccess(searchResult));
  } catch (e) {
    yield call(
      errorHandler,
      ErrorBuilder.error(e),
      action,
      actions.searchError,
    );
    return;
  }
}
