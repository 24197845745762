import { TreeNode } from '../../api/xtree';
import { NodesMap } from '../../features/Tree';
import { PermalinkBuilder } from '../../routes/PermalinkBuilder';
import { findFirstPathPerParentId } from '../hierarchy/findFirstPathPerParentId';
import {
  createHierarchy,
  getHierarchyIdsByRelationType,
  getNodeLabelIdsInHierarchy,
  HierarchyRelation,
  hierarchyStrToArray,
} from '../hierarchy/hierarchyBuilder';
import {
  NormalizerEntities,
  VocabType,
  VocabTypes,
  XTreeVocabularyItem,
} from '../normalizer';
import { StringUtils } from '../StringUtils';
import { MapItems } from './MapItems';
import { Notes } from './Notes';
import { RelatedVocabItems } from './RelatedVocabItems';
import { VocabImages } from './VocabImages';

export abstract class VocabularyItem {
  protected _item: XTreeVocabularyItem;
  protected _type: VocabType = VocabTypes.Concept;
  protected _responseEntities: NormalizerEntities = {};
  protected _notes: Notes;
  protected _mapItems: MapItems;
  protected _terms: string[];
  protected _related: RelatedVocabItems;
  // protected _processedHierarchies: boolean = false;
  protected _hierarchy: string[][] = [];
  protected _partitiveHierarchy: string[][] = [];
  protected _partitiveIds: string[] = [];

  protected constructor(item: XTreeVocabularyItem) {
    this._item = item;
    this._notes = new Notes(item.Note);
    this._mapItems = new MapItems(item.MapItem);
    this._terms = item.Term;
    this._related = new RelatedVocabItems(item.related);
  }

  public static create<V extends VocabularyItem>(
    VocabInstance: new (item: XTreeVocabularyItem) => V,
    type: VocabType,
    item: XTreeVocabularyItem,
    entities: NormalizerEntities,
  ) {
    const vocab: V = new VocabInstance(item);
    vocab._type = type;
    vocab.responseEntities = entities;
    vocab._related.init(entities);
    return vocab;
  }

  protected get item() {
    return this._item;
  }

  set responseEntities(entities: NormalizerEntities) {
    this._responseEntities = entities;
  }

  get id() {
    return this.item.id;
  }

  get type() {
    return this._type;
  }

  get permalink(): string {
    return PermalinkBuilder.build(this.item.id);
  }

  get preferredTerm(): string {
    return this.item.preferredTerm;
  }

  get title(): string {
    return StringUtils.termWithQualifier(
      this.item.preferredTerm,
      this.item.qualifier,
    );
  }

  get uri(): string {
    return `${this.item.inScheme}/${this.item.id}`;
  }

  get lastUpdated(): string {
    return this.item.lastUpdated;
  }

  get status(): string {
    return this.item.status;
  }

  get processingStatus(): string {
    return this.item.processingStatus;
  }

  get notes(): Notes {
    return this._notes;
  }

  get mapItems() {
    return this._mapItems.items;
  }

  get terms(): string[] {
    return this._terms;
  }

  get related() {
    return this._related;
  }

  get hasHierarchy() {
    return this._hierarchy.length > 0;
  }

  get hierarchy(): string[][] {
    return this._hierarchy;
  }

  get hasPartitiveHierarchy() {
    return this._partitiveHierarchy.length > 0;
  }

  get partitiveHierarchy(): string[][] {
    return this._partitiveHierarchy;
  }

  get partitiveIds(): string[] {
    return this._partitiveIds;
  }

  get associativeRelations() {
    return this._item.associativeRelation;
  }

  get vocabImages() {
    if (
      this._item.resourceRepresentation &&
      this._item.resourceRepresentation.length
    ) {
      const vocabImages = new VocabImages(this._item.resourceRepresentation);
      return vocabImages.images;
    }
    return null;
  }

  public computeHierarchies(nodesMap: NodesMap) {
    const hierarchy: string[] | null = createHierarchy(this.id, nodesMap);
    function isNodeLabel(item) {
      const node: TreeNode = nodesMap[item.id];
      return node.entityType === VocabTypes.ThesaurusArray;
    }

    if (!!hierarchy) {
      // NodeLabels don't have the property typeOfHierarchicalRelation.
      // (it isn't there for neither their parents nor their children)
      if (isNodeLabel(this._item)) {
        this._hierarchy = hierarchyStrToArray(hierarchy);
        return;
      }

      const idsWithoutRelationType: string[] = getNodeLabelIdsInHierarchy(
        hierarchy,
        nodesMap,
      );
      const itemsGeneric = getHierarchyIdsByRelationType(
        this._item.superordinateRelation,
        HierarchyRelation.Generic,
        idsWithoutRelationType,
      );
      const idsGeneric = findFirstPathPerParentId(
        this.id,
        hierarchy,
        itemsGeneric,
      );
      this._hierarchy = hierarchyStrToArray(idsGeneric);

      const partitiveIds = getHierarchyIdsByRelationType(
        this._item.superordinateRelation,
        HierarchyRelation.Partitive,
        [],
      );
      this._partitiveIds = partitiveIds;
      const hierarchyIds = findFirstPathPerParentId(
        this.id,
        hierarchy,
        partitiveIds,
      );
      this._partitiveHierarchy = hierarchyStrToArray(hierarchyIds);
    }
  }

  public shouldDisplayRole() {
    return ![
      VocabTypes.FacetNode,
      VocabTypes.HierarchyNode,
      VocabTypes.ThesaurusArray,
      VocabTypes.NonIndexingConcept,
    ].includes(this._type);
  }
}
