import { TFunction } from 'i18next';
import { TranslationUtils } from '../../locales/TranslationUtils';
import { VocabTypes } from './normalizer';

export class StringUtils {
  public static joinNonEmptyStrings(list: string[], glue: string): string {
    return list.filter(Boolean).join(glue);
  }

  public static termWithQualifier(term: string, qualifier?: string): string {
    let suffix = '';
    if (qualifier) {
      suffix = ` (${qualifier})`;
    }
    return `${term}${suffix}`;
  }

  public static termWithEntityType(
    title: string,
    entityType: string,
    t: TFunction,
  ) {
    if (
      [
        VocabTypes.FacetNode.toString(),
        VocabTypes.HierarchyNode.toString(),
      ].includes(entityType)
    ) {
      const translatedType = t(TranslationUtils.vocabularyType(entityType));
      return StringUtils.termWithQualifier(title, translatedType);
    }
    return title;
  }
}
