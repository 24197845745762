import { XTreeMapItem } from '../normalizer';

export class MapItem {
  protected _item: XTreeMapItem;

  public constructor(item: XTreeMapItem) {
    this._item = item;
  }

  get mappingRelation(): string {
    const relation = this.hasMatch ? this._item.mappingRelation : 'noMatch';
    return relation;
  }

  get hasMatch(): boolean {
    const mappingNotFoundMarker: number = this.mappingNotFoundMarker;
    if (mappingNotFoundMarker === 1) {
      return false;
    }
    return true;
  }

  get mappingNotFoundMarker(): number {
    const value: any = this._item.mapping_not_found_marker;
    if (!value) {
      return 0;
    }
    if (typeof value === 'string') {
      return Number.parseInt(value);
    }
    return value;
  }

  get mapItemID(): string {
    return this._item.mapItemID;
  }

  get mapItemIDType(): string {
    return this._item.mapItemIDType;
  }

  get mapItemLabel(): string {
    return this._item.mapItemLabel;
  }

  get mapItemSource(): string {
    return this._item.mapItemSource;
  }
}
