import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { PageLayout } from '../components/PageLayout';

export const AboutPage = lazyLoad(
  () => import('./AboutPage'),
  module => module.AboutPage,
  { fallback: <PageLayout /> },
);

export const DatenschutzPage = lazyLoad(
  () => import('./DatenschutzPage'),
  module => module.DatenschutzPage,
  { fallback: <PageLayout /> },
);

export const AccessibilityPage = lazyLoad(
  () => import('./AccessibilityPage'),
  module => module.AccessibilityPage,
  { fallback: <PageLayout /> },
);
export const HelpPage = lazyLoad(
  () => import('./HelpPage'),
  module => module.HelpPage,
  { fallback: <PageLayout /> },
);

export const HomePage = lazyLoad(
  () => import('./HomePage'),
  module => module.HomePage,
  { fallback: <PageLayout /> },
);

export const ImpressumPage = lazyLoad(
  () => import('./ImpressumPage'),
  module => module.ImpressumPage,
  { fallback: <PageLayout /> },
);

export const NotFoundPage = lazyLoad(
  () => import('./NotFoundPage'),
  module => module.NotFoundPage,
  { fallback: <PageLayout /> },
);

export const ResultPage = lazyLoad(
  () => import('./ResultPage'),
  module => module.ResultPage,
  { fallback: <PageLayout /> },
);

export const SearchPage = lazyLoad(
  () => import('./SearchPage'),
  module => module.SearchPage,
  { fallback: <PageLayout /> },
);

export const TeamPage = lazyLoad(
  () => import('./TeamPage'),
  module => module.TeamPage,
  { fallback: <PageLayout /> },
);
