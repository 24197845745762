import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions, selectIsMobileMenuOpen } from '../../../features/MobileMenu';
import { IconBurger, IconBurgerClose } from '../../Icon';
import { Button } from './style';

export function MobileMenuButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isMobileMenuOpen = useSelector(selectIsMobileMenuOpen);

  const handleClick = evt => {
    // evt.preventDefault();
    dispatch(actions.toggleMobileMenu());
  };

  return (
    <Button
      onClick={handleClick}
      aria-label={t(
        `Menu.Mobile.${isMobileMenuOpen ? 'close' : 'open'}.ariaLabel`,
      )}
      aria-haspopup
      aria-expanded={isMobileMenuOpen}
      aria-controls="main-navigation"
      aria-hidden={true}
    >
      {isMobileMenuOpen ? (
        <IconBurgerClose aria-labelledby="title" />
      ) : (
        <IconBurger aria-labelledby="title" />
      )}
    </Button>
  );
}
