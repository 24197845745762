import { call, put, select } from 'redux-saga/effects';
import xTreeApi, {
  ErrorBuilder,
  errorHandler,
  parseXtreeResponse,
} from '../../../api';
import { XTreeJsonResponse } from '../../../model/normalizer';
import { selectNodesMap, selectRequestedIds } from '../selectors';
import { actions } from '../slice';
import { NodesMap } from '../types';

export function* loadTreeLevelTopDown(action) {
  const { id } = action.payload;
  let nodesMap: NodesMap = yield select(selectNodesMap);

  const hasItemsToRequest =
    !nodesMap.hasOwnProperty(id) ||
    (nodesMap[id].childrenIds.length &&
      nodesMap[id].childrenIds.some(cId => !nodesMap.hasOwnProperty(cId)));

  const successPayload: {
    id: string;
    nodesMap?: NodesMap;
    requestedIds?: string[];
  } = {
    id,
  };
  if (hasItemsToRequest) {
    const node = nodesMap[id];

    const options = {
      direction: xTreeApi.XTreeParams.Direction.down,
      jsonFull: xTreeApi.XTreeParams.JsonFull.custom,
      level: xTreeApi.XTreeParams.Level.one,
      nodeid: id,
    };

    if (node) {
      try {
        const response: XTreeJsonResponse = yield call(
          xTreeApi.getFetchHierarchy,
          options,
        );
        const data = parseXtreeResponse(response);
        nodesMap = {
          ...nodesMap,
          ...(data.nodesMap as NodesMap),
        };
        const requestedIds: string[] = yield select(selectRequestedIds);
        successPayload.nodesMap = nodesMap;
        successPayload.requestedIds = [...requestedIds, id];
      } catch (e) {
        yield call(
          errorHandler,
          ErrorBuilder.error(e),
          action,
          actions.loadTreeError,
        );
      }
    }
  }

  yield put(actions.loadTreeSuccess(successPayload));
}
