import styled from 'styled-components';

interface LoaderWrapperProps {
  $isPartOnly: boolean;
  theme: any;
}

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: ${props => (props.$isPartOnly ? 'absolute' : 'fixed')};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${props =>
    props.$isPartOnly ? 'transparent' : 'rgba(0, 0, 0, 0.1)'};
  color: ${props => props.theme.color_gray_6};
  width: ${props => (props.$isPartOnly ? '100%' : '100vw')};
  height: ${props => (props.$isPartOnly ? '100%' : '100vh')};
`;
