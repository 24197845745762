import styled, { css } from 'styled-components/macro';
import { IconButton } from '../Button/IconButton';
import { media, font_BodyLight, font_HighlightedTerm } from '../../../styles';

interface SearchBarWrapperProps {
  $isFocused: boolean;
}
export const SearchBarWrapper = styled.div<SearchBarWrapperProps>`
  grid-area: search-input;
  background: ${props => props.theme.color.white};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.5);

  padding: ${props => props.theme.spacing._10}rem;
  height: ${props => props.theme.spacing._50}rem;

  input[type='text'] {
    border: none;
    ${font_BodyLight};
  }

  ${media.tablet_portrait_up`
    padding: ${props => props.theme.spacing._11}rem;
  `}
  ${props =>
    props.$isFocused &&
    css`
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.5),
        0px 0px 4px 1px ${props.theme.color.link_blue};
    `};
`;

export const TermInput = styled.input`
  flex: 1 0;
  outline: 0;
`;

export const Button = styled(IconButton)`
  cursor: pointer;
  ${media.tablet_portrait_up`
    margin-left: ${props => props.theme.spacing._28}rem;
  `}
  &:hover svg {
    color: ${props => props.theme.color.link_blue};
  }
`;

export const SearchButtonWrapper = styled.div`
  margin-left: auto;
`;

export const HighlightedTermButton = styled(IconButton)`
  cursor: pointer;
  margin-left: ${props => props.theme.spacing._10}rem;
  &:hover svg {
    color: ${props => props.theme.color.link_blue};
  }
`;

export const HighlightedTerm = styled.div`
  ${font_HighlightedTerm};
  background-color: ${props => props.theme.color.dark_blue};
  padding: 5px;
`;
