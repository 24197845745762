import { XTreeJsonResponse } from '../../../model/normalizer';
import { call, put } from 'redux-saga/effects';
import xTreeApi, {
  ErrorBuilder,
  errorHandler,
  parseXtreeResponse,
} from '../../../api';
import { XTreeParams } from '../../../api/xtree';
import { NodesMap, actions } from '../index';

export function* loadIdsStandard(action) {
  try {
    const { ids } = action.payload;
    const response: XTreeJsonResponse = yield call(
      xTreeApi.getSearchVocItemsById,
      ids,
      XTreeParams.JsonFull.standard,
    );

    const parsedResponse = parseXtreeResponse(response);
    if (parsedResponse.error) {
      throw ErrorBuilder.error(parsedResponse);
    }

    const nodes: NodesMap = parsedResponse.nodesMap as NodesMap;
    yield put(actions.loadIdsStandardSuccess({ nodes }));
  } catch (e) {
    yield call(
      errorHandler,
      ErrorBuilder.error(e),
      action,
      actions.loadIdsStandardError,
    );
    return;
  }
}
