import { call } from 'redux-saga/effects';
import { buildRequestUrl } from '../buildRequestUrl';
import { buildRequestQuery } from '../buildRequestQuery';
import { ENDPOINT } from '../constants';
import { XTreeParams } from './constants';
import { XTreeQueryParams } from './types';
import { authenticatedRequest } from './login';

export function* getSearchVocItemsByTerm(term: string) {
  const options: XTreeQueryParams = {
    searchTermsList: term,
    mode: XTreeParams.Mode.or,
    searchFields: XTreeParams.SearchFields.all,
    jsonFull: XTreeParams.JsonFull.termSort,
    sortList: 'all',
    public: 1,
    lang: 'all',
  };
  const query = yield buildRequestQuery(options);
  const url = yield call(buildRequestUrl, ENDPOINT.SearchByTerm, query);
  return yield call(authenticatedRequest, url);
}
