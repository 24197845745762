import { useEffect, useState } from 'react';
import { useQueryParam } from '../../hooks/useQuery';

export function useSearchBarState() {
  const [searchTerm, setSearchTerm] = useState('');
  const [focus, setFocus] = useState(false);

  let termQueryParam = useQueryParam('term');
  termQueryParam = termQueryParam?.trim() ?? '';

  useEffect(() => {
    if (termQueryParam) {
      setSearchTerm(termQueryParam);
    }
  }, [termQueryParam]);

  return {
    focus,
    setFocus,
    searchTerm,
    setSearchTerm,
    termQueryParam,
  };
}
