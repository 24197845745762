import { css } from 'styled-components/macro';
import * as styleGuide from './typography-styleguide';

/**
 * ---------------------------------------------------------------
 * The fonts as used by our components.
 * ---------------------------------------------------------------
 */

// Headers
export const font_H1 = styleGuide.font_h1;
export const font_H2 = styleGuide.font_H2;
export const font_H3 = styleGuide.font_H3;

// Content (Body, Labels, Link)
export const font_Body = styleGuide.font_body;
export const font_BodyBold = styleGuide.font_body_bold;
export const font_BodyLight = styleGuide.font_body_light;

export const font_Link = styleGuide.font_link;

export const font_Header = styleGuide.font_header;

export const font_Footer = styleGuide.font_footer;

export const font_ResultsTotal = styleGuide.font_total_results;

export const font_ResultLink = styleGuide.font_result_link;

export const font_HighlightedTerm = styleGuide.font_highlighted_term;

export const font_TreeItem = styleGuide.font_tree_item;
export const font_TreeItemFocused = styleGuide.font_tree_item_focused;

export const font_SuggestedTerm = styleGuide.font_suggested_term;

export const font_Teaser = styleGuide.font_teaser;
export const font_TeaserLight = css`
  ${styleGuide.font_body_light};
  color: ${props => props.theme.color.dark_blue};
`;
export const font_TeaserBuch = css`
  ${styleGuide.font_body_buch};
  color: ${props => props.theme.color.dark_blue};
`;

export const font_BodyBookBold = styleGuide.font_body_book_bold;

export const font_CTA = styleGuide.font_results_cta;
export const font_CTA_Link = styleGuide.font_results_cta_link;

export const font_ImageCaption = styleGuide.font_caption;
