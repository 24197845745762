import { NormalizerResponse } from '../normalizer';

export const relevantEntities = [
  'Concept',
  'ConceptGroup',
  'FacetNode',
  'HierarchyNode',
  'NonIndexingConcept',
  'ThesaurusArray',
];

function toListOfId(normalizedData) {
  return Object.keys(normalizedData.entities)
    .filter((entityType: string) => relevantEntities.includes(entityType))
    .reduce((acc: string[], entityType: string) => {
      const items = normalizedData.entities[entityType];
      return [...acc, ...Object.keys(items)];
    }, []);
}

function mergeList(list1, list2) {
  return [...list1, ...list2];
}

export function mergeDataAsList(stateList, data) {
  return mergeList(stateList, toListOfId(data));
}

function toMapById(normalizedData: NormalizerResponse) {
  return Object.keys(normalizedData.entities)
    .filter((entityType: string) => relevantEntities.includes(entityType))
    .reduce((acc: any, entityType: string) => {
      const items = normalizedData.entities[entityType];
      return { ...acc, ...items };
    }, {});
}
function mergeMap(map1, map2) {
  return { ...map1, ...map2 };
}

export function mergeDataAsMap(stateMap, data) {
  return mergeMap(stateMap, toMapById(data));
}
