import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelectedVocab } from '../../hooks/useSelectedVocab';
import { VocabularyItem } from '../../model/vocabularyItems/VocabularyItem';
import { RouteUtils } from '../../routes';
import { toSlug } from '../../routes/PermalinkBuilder';
import { IconSearch, IconReset } from '../Icon';
import { useSearchBarHandlers } from './handlers';
import { messages } from './messages';
import { useSearchBarState } from './state';
import {
  SearchBarWrapper,
  TermInput,
  Button,
  HighlightedTerm,
  HighlightedTermButton,
  SearchButtonWrapper,
} from './style';

export function SearchBar() {
  const { t } = useTranslation();
  const state = useSearchBarState();
  const handlers = useSearchBarHandlers(state);
  const vocab: VocabularyItem | null = useSelectedVocab();
  const vocabTerm = vocab?.preferredTerm;
  const location = useLocation();
  const isDetailsPage = RouteUtils.isDetailsPage(location);

  const { setSearchTerm, termQueryParam } = state;

  useEffect(() => {
    if (termQueryParam && vocabTerm && termQueryParam === toSlug(vocabTerm)) {
      setSearchTerm(vocabTerm);
    }
  }, [setSearchTerm, termQueryParam, vocabTerm]);

  return (
    <SearchBarWrapper $isFocused={state.focus}>
      {isDetailsPage && vocabTerm && (
        <>
          <HighlightedTerm>{vocabTerm}</HighlightedTerm>
          <HighlightedTermButton
            onClick={handlers.doEmptySearch}
            title={t(messages.resetInputTooltip)}
          >
            <IconReset title={t(messages.resetInputAriaLabel)} />
          </HighlightedTermButton>
        </>
      )}

      <TermInput
        type="text"
        placeholder={isDetailsPage ? undefined : t(messages.placeholder)}
        aria-label={t(messages.placeholderAriaLabel)}
        value={state.searchTerm}
        onChange={handlers.onChange}
        onKeyUp={handlers.onKeyUp}
        onFocus={handlers.onFocus}
        onBlur={handlers.onBlur}
        autoComplete="off"
        id="search-input"
      />

      {state.searchTerm && (
        <Button
          onClick={handlers.resetTerm}
          title={t(messages.resetInputTooltip)}
        >
          <IconReset title={t(messages.resetInputAriaLabel)} />
        </Button>
      )}

      <SearchButtonWrapper>
        <Button
          onClick={
            isDetailsPage ? handlers.doEmptySearch : handlers.submitSearchTerm
          }
          title={t(messages.searchTooltip)}
        >
          <IconSearch title={t(messages.searchAriaLabel)} />
        </Button>
      </SearchButtonWrapper>
    </SearchBarWrapper>
  );
}
