import { DefaultTheme } from 'styled-components/macro';

const baseFontSize: Pick<DefaultTheme, 'baseFontSize'>['baseFontSize'] = 16;

const breakpoints_in_px: Pick<DefaultTheme, 'breakpoints'>['breakpoints'] = {
  mobile: 414,
  tablet_portrait: 768,
  tablet_landscape: 1024,
  desktop: 1280,
};

const grid_in_rem: Pick<DefaultTheme, 'grid'>['grid'] = {
  gutter: {
    mobile: 1.0625, // 17px
    tablet_portrait: 1.5, // 24px
    // tablet_landscape: 2.0625, //33px
    desktop: 2.5, // 40px
  },
};

const page_padding_in_rem: Pick<DefaultTheme, 'pagePadding'>['pagePadding'] = {
  mobile: 1.9375, // 31px
  tablet_portrait: 3.25, // 52px
  // tablet_landscape: grid_in_rem.gutter.tablet_landscape,
  // desktop: 6.875, // 110px
  desktop: grid_in_rem.gutter.desktop,
};

type ToRemFn = (
  min: number,
  max: number,
) => Pick<DefaultTheme, 'spacing'>['spacing'];
const spacing_in_rem: ToRemFn = (min: number, max: number) => {
  return [...Array(max - min + 1).keys()].reduce((acc: {}, key) => {
    const n = min + key;
    acc[`_${n}`] = n / baseFontSize;
    return acc;
  }, {});
};

const font: Pick<DefaultTheme, 'font'>['font'] = {
  family: 'Soehne, sans-serif',
  weight: {
    light: 300,
    regular: 400,
    bold: 500,
  },
  size: spacing_in_rem(11, 35),
};

const color: Pick<DefaultTheme, 'color'>['color'] = {
  blue_gray: '#DDDFE1',
  dark_blue: '#192739',
  dark_blue_80_percent: 'rgba(25, 39, 57, 0.8)',
  link_blue: '#0057AE',
  light_green: '#EEF5E0',
  light_blue: '#D7E2FF',
  light_yellow: '#FFF3CF',
  light_pink: '#FFE0E0',
  dark_gray: '#3D3D3D', // text
  medium_gray: '#6A6A6A', // headers
  light_gray: '#525252', // search box placeholder
  cta_box_gray: '#F5F5F5', // results view cta
  line_gray: '#C4C4C4', // search result line
  bg_gray: '#F4F5F5', // image background
  black: '#000',
  white: '#FFF',

  icon_facet_salmon: '#FFE4D0',
  icon_hierarchy_yellow: '#FFF3CF',
  icon_concept_green: '#EEF5E0',
  icon_concept_gray: '#EBEBEB',
  icon_node_pink: '#FFE0E0',
};

export const defaultTheme: DefaultTheme = {
  breakpoints: breakpoints_in_px,
  grid: grid_in_rem,
  pagePadding: page_padding_in_rem,
  spacing: spacing_in_rem(3, 350),
  baseFontSize,
  font: font,
  color: color,
};
