import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useQueryParam(name: string) {
  const query = new URLSearchParams(useLocation().search);
  return query.get(name);
}
