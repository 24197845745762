import { createGlobalStyle } from 'styled-components/macro';
import normalize from 'styled-normalize';
import { font_Body } from './typography';
import { defaultTheme } from './defaultTheme';
import { fontFaces } from './fontFaces';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${fontFaces};

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    overflow-y: scroll;
    font-family: ${defaultTheme.font.family};
    -webkit-touch-callout: none;
    ${font_Body};
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
