/** NormalizerEntities */
export interface NormalizerEntities {
  concept?: { [id: string]: XTreeConcept };
  conceptGroup?: { [id: string]: XTreeConceptGroup };
  facetNode?: { [id: string]: XTreeFacetNode };
  hierarchyNode?: { [id: string]: XTreeFacetNode };
  nonIndexingConcept?: { [id: string]: XTreeNonIndexingConcept };
  term?: { [id: string]: XTreeTerm };
  thesaurusArray?: { [id: string]: XTreeThesaurusArray };
}

/** NormalizerResult */
export interface NormalizerResult {
  [nodeType: string]: string; // item id
}

/** NormalizerResponse */
export interface NormalizerResponse {
  entities: NormalizerEntities;
  result: NormalizerResult[];
}

/** XTreeJsonResponse */
export interface XTreeJsonResponse {
  vocItemCount: number;
  VocabularyItem: XTreeVocabularyItem[];
}

export enum VocabTypes {
  Concept = 'Concept',
  ConceptGroup = 'ConceptGroup',
  FacetNode = 'FacetNode',
  HierarchyNode = 'HierarchyNode',
  NonIndexingConcept = 'NonIndexingConcept',
  ThesaurusArray = 'ThesaurusArray',
}

export type VocabType =
  | VocabTypes.Concept
  | VocabTypes.ConceptGroup
  | VocabTypes.FacetNode
  | VocabTypes.HierarchyNode
  | VocabTypes.NonIndexingConcept
  | VocabTypes.ThesaurusArray;

export interface XTreeVocabularyItem {
  id: string;
  status: string;
  notation: string;
  displayPosition: string;
  earliestDate: string;
  latestDate: string;
  pk: string;
  processingStatus: string;
  lastUpdated: string;
  inScheme: boolean;
  Term: string[];
  Note: XTreeNote[];
  MapItem: XTreeMapItem[];
  related: Record<string, string>[];
  associativeRelation: AssociativeRelation[];
  resourceRepresentation: ResourceRepresentation[];
  superordinateRelation?: HierarchicalNode[];
  subordinateRelation?: HierarchicalNode[];

  // added to schema
  // notes: any[];
  preferredTerm: string;
  preferredTermId: string;
  qualifier?: string;
}

export interface ResourceTitle {
  de: string;
}

export interface ResourceRepresentation {
  providedImage: string;
  previewImage: string;
  rightsHolder: string;
  rightsType: string;
  source: string;
  title: ResourceTitle[];
  photographer: string;
  description: ResourceTitle[];
}
export interface HierarchicalNode {
  pk: string;
  id: string;
  typeOfHierarchicalRelation?: string;
}

export interface AssociativeRelation {
  id: string;
  pk: string;
  typeOfAssociativeRelation: string;
}

export interface XTreeTerm {
  labelRole: string;
  lang: string;
  grammaticalNumber: string;
  pk: string;
  Term: string;
  qualifier: string;
}

export interface XTreeNote {
  lang: string;
  source: string;
  changeNote?: string;
  definition?: string;
  editorialNote?: string;
  etmology?: string;
  example?: string;
  explanation?: string;
  extDefinition?: string;
  glossaryEntry?: string;
  historyNote?: string;
  miscNote?: string;
  note?: string;
  scopeNote?: string;
}

export interface XTreeMapItem {
  mappingRelation:
    | 'mappingRelation'
    | 'closeMatch'
    | 'exactMatch'
    | 'broadMatch'
    | 'narrowMatch'
    | 'relatedMatch';
  mapItemID: string;
  mapItemIDType: 'uri' | 'url' | 'hdl' | 'local' | 'urn' | 'doi';
  mapItemLabel: string;
  mapItemSource: string;
  mapping_not_found_marker?: number;
}

export interface XTreeConcept extends XTreeVocabularyItem {
  broader: XTreeVocabularyItem[];
  narrower: XTreeVocabularyItem[];
  isMemberOf: XTreeVocabularyItem[];
  subordinateArray: XTreeVocabularyItem[];
  vocabularyType:
    | 'Concept'
    | 'ConceptGroup'
    | 'FacetNode'
    | 'HierarchyNode'
    | 'NonIndexingConcept'
    | 'ThesaurusArray';
}
export interface XTreeNonIndexingConcept extends XTreeConcept {}
export interface XTreeFacetNode extends XTreeConcept {}

export interface XTreeConceptGroup extends XTreeVocabularyItem {
  superGroup: XTreeConceptGroup[];
  subGroup: XTreeConceptGroup[];
  narrower: XTreeVocabularyItem[];
  member: XTreeVocabularyItem[];
}

export interface XTreeThesaurusArray extends XTreeVocabularyItem {
  superOrdinate: XTreeVocabularyItem[];
  member: XTreeVocabularyItem[];
  isMemberOf: XTreeThesaurusArray;
}

export enum XTreeStatus {
  candidate = 'candidate',
  approved = 'approved',
}

export enum XTreeProcessingStatus {
  closed = 'closed',
}
