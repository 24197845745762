import { call, put } from 'redux-saga/effects';
import xTreeApi, {
  ErrorBuilder,
  errorHandler,
  parseXtreeResponse,
} from '../../../api';
import { XTreeJsonResponse } from '../../../model/normalizer';
import { actions } from '../slice';
import { NodesMap } from '../types';
import { XTreeParams } from '../../../api/xtree';
import { NodeInspector } from '../../../model/tree/NodeInspector';

export function* loadRoots(action) {
  let response: XTreeJsonResponse;
  let nodesMap: NodesMap = {};

  // FETCH ROOT NODE
  try {
    const options = {
      direction: xTreeApi.XTreeParams.Direction.up,
      jsonFull: xTreeApi.XTreeParams.JsonFull.custom,
      level: xTreeApi.XTreeParams.Level.N,
      typeofVocItem: xTreeApi.XTreeParams.VocItemType.onlyFacetNodes,
    };
    response = yield call(xTreeApi.getFetchHierarchy, options);
    const dataRoot = parseXtreeResponse(response);

    if (dataRoot.nodesMap) {
      nodesMap = dataRoot.nodesMap;
    }
  } catch (e) {
    yield call(
      errorHandler,
      ErrorBuilder.error(e),
      action,
      actions.loadTreeError,
    );
  }

  // FETCH FURTHER IDs: children of open terms in the tree
  const rootId = NodeInspector.getRootNode();
  if (nodesMap[rootId].childrenIds) {
    try {
      response = yield call(
        xTreeApi.getSearchVocItemsById,
        nodesMap[rootId].childrenIds,
        XTreeParams.JsonFull.custom,
      );
      const dataRootChildren = parseXtreeResponse(response);

      nodesMap = {
        ...nodesMap,
        ...(dataRootChildren.nodesMap as NodesMap),
      };
    } catch (e) {
      yield call(
        errorHandler,
        ErrorBuilder.error(e),
        action,
        actions.loadTreeError,
      );
    }
  }

  yield put(actions.loadTreeSuccess({ nodesMap }));
  yield put(actions.selectNode({ id: '' }));
}
