import { select } from 'redux-saga/effects';
import { selectXTreeVocabularyEncoded } from '../features/Config';
import { XTreeQueryParams, XTreeParams } from './xtree';

export const defaultOptions: XTreeQueryParams = {
  start: '0',
  jsonFull: XTreeParams.JsonFull.standard,
  lang: 'all',
};

export function* buildRequestQuery(requestOptions: XTreeQueryParams) {
  const vocabulary = yield select(selectXTreeVocabularyEncoded);
  const options: XTreeQueryParams = { ...requestOptions, vocabulary };
  const optionsArray: string[] = Object.keys(options).map((key: string) => {
    const name = key !== 'sortList' ? key.toLowerCase() : key;
    return `${name}=${options[key]}`;
  });
  return optionsArray.join('&');
}
