import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

// First makeSelect the relevant part from the state
const selectAppConfiguration = (state: RootState) =>
  state.config || initialState;

export const selectApiUrl = createSelector(
  selectAppConfiguration,
  config => config.xTreeApi.url,
);

export const selectXTreeVocabulary = createSelector(
  selectAppConfiguration,
  config => config.xTreeApi.vocabulary,
);

export const selectXTreeVocabularyEncoded = createSelector(
  selectAppConfiguration,
  config => encodeURIComponent(config.xTreeApi.vocabulary),
);

export const selectXTreeApiUsername = createSelector(
  selectAppConfiguration,
  config => config.xTreeApi.username,
);

export const selectXTreeApiPassword = createSelector(
  selectAppConfiguration,
  config => config.xTreeApi.password,
);

export const selectXTreeApiFormLoginName = createSelector(
  selectAppConfiguration,
  config => config.xTreeApi.formLoginName,
);

export const selectXTreeApiFormLoginId = createSelector(
  selectAppConfiguration,
  config => config.xTreeApi.formLoginId,
);

export const selectMatomoUrl = createSelector(
  selectAppConfiguration,
  config => config.matomo.url,
);

export const selectMatomoSiteId = createSelector(
  selectAppConfiguration,
  config => config.matomo.siteId,
);
