import { schema } from 'normalizr';

const addVocabularyType = (vocabType: string) => entity => {
  const clone = { ...entity };
  clone.vocabularyType = vocabType;
  return clone;
};

/** *****************************************
 * Declare main schemas
 * - avoid "schema not defined" by declaring
 *   the schemas before their definitions
 * ****************************************** */
const concept = new schema.Entity('Concept', undefined, {
  processStrategy: addVocabularyType('Concept'),
});
const conceptGroup = new schema.Entity('ConceptGroup', undefined, {
  processStrategy: addVocabularyType('ConceptGroup'),
});
const facetNode = new schema.Entity('FacetNode', undefined, {
  processStrategy: addVocabularyType('FacetNode'),
});
const hierarchyNode = new schema.Entity('HierarchyNode', undefined, {
  processStrategy: addVocabularyType('HierarchyNode'),
});
const nonIndexingConcept = new schema.Entity('NonIndexingConcept', undefined, {
  processStrategy: addVocabularyType('NonIndexingConcept'),
});
const thesaurusArray = new schema.Entity('ThesaurusArray', undefined, {
  processStrategy: addVocabularyType('ThesaurusArray'),
});

/** Schema: VocabularyItem */
export const vocabularyItemSchema = new schema.Array({
  Concept: concept,
  ConceptGroup: conceptGroup,
  FacetNode: facetNode,
  HierarchyNode: hierarchyNode,
  NonIndexingConcept: nonIndexingConcept,
  ThesaurusArray: thesaurusArray,
});

/** *****************************************
 * Secondary Schemas used by the main Schemas
 * ****************************************** */

const addPreferredTerm = (term, entity) => {
  const clone = { ...term };
  if (clone['labelRole'] === 'prefLabel' && clone['lang'] === 'de') {
    entity.preferredTerm = term['Term'];
    entity.preferredTermId = term['pk'];
    if (!['Facette', 'Hierarchiename'].includes(term['qualifier'])) {
      entity.qualifier = term['qualifier'];
    } else {
      entity.vocabTypeQualifier = term['qualifier'];
    }
  }
  return clone;
};

/** attribute: Term */
const term = new schema.Entity(
  'term',
  {},
  {
    idAttribute: 'pk',
    processStrategy: addPreferredTerm,
  },
);

/** attribute: broader */
const broader = new schema.Array({
  Concept: concept,
  NonIndexingConcept: nonIndexingConcept,
  FacetNode: facetNode,
  HierarchyNode: hierarchyNode,
});

/** attribute: narrower */
const narrower = new schema.Array({
  Concept: concept,
  NonIndexingConcept: nonIndexingConcept,
  FacetNode: facetNode,
  HierarchyNode: hierarchyNode,
});

/** attribute: isMemberOf */
const memberOf = new schema.Array({
  ThesaurusArray: thesaurusArray,
  ConceptGroup: conceptGroup,
});

/** attribute: subordinateArray */
const subordinateArray = new schema.Array({
  ThesaurusArray: thesaurusArray,
});

/** attribute: related */
const related = new schema.Array({
  Concept: concept,
  ConceptGroup: conceptGroup,
  FacetNode: facetNode,
  HierarchyNode: hierarchyNode,
  NonIndexingConcept: nonIndexingConcept,
  ThesaurusArray: thesaurusArray,
});

/** attribute: superordinateRelation */
const superordinateRelation = new schema.Array({
  Concept: concept,
  FacetNode: facetNode,
  HierarchyNode: hierarchyNode,
  NonIndexingConcept: nonIndexingConcept,
  ThesaurusArray: thesaurusArray,
});

/** attribute: subordinateRelation */
const subordinateRelation = new schema.Array({
  Concept: concept,
  FacetNode: facetNode,
  HierarchyNode: hierarchyNode,
  NonIndexingConcept: nonIndexingConcept,
  ThesaurusArray: thesaurusArray,
});

/** *****************************************
 * Definitions of circular references in the Schema
 * ****************************************** */

/** Concept */
const commonAttributes = {
  Term: [term],
  related: related,
  subordinateRelation: superordinateRelation,
  superordinateRelation: subordinateRelation,
};
const conceptDefinition = {
  ...commonAttributes,
  broader: broader,
  narrower: narrower,
  isMemberOf: memberOf,
  subordinateArray: subordinateArray,
};
concept.define(conceptDefinition);

/** ConceptGroup */
conceptGroup.define({
  ...commonAttributes,
  superGroup: [conceptGroup],
  subGroup: [conceptGroup],
  narrower: [
    {
      Concept: concept,
      NonIndexingConcept: nonIndexingConcept,
      FacetNode: facetNode,
      HierarchyNode: hierarchyNode,
    },
  ],
  member: [
    {
      Concept: concept,
      NonIndexingConcept: nonIndexingConcept,
      FacetNode: facetNode,
      HierarchyNode: hierarchyNode,
    },
  ],
});

/** FacetNode: uses same definition as Concept */
facetNode.define(conceptDefinition);

/** HierarchyNode: uses same definition as Concept */
hierarchyNode.define(conceptDefinition);

/** NonIndexingConcept: uses same definition as Concept */
nonIndexingConcept.define({ ...conceptDefinition, isMemberOf: thesaurusArray });

/** ThesaurusArray */
thesaurusArray.define({
  ...commonAttributes,
  superOrdinate: [
    {
      Concept: conceptDefinition,
      NonIndexingConcept: nonIndexingConcept,
      FacetNode: facetNode,
      HierarchyNode: hierarchyNode,
    },
  ],
  member: [
    {
      Concept: conceptDefinition,
      NonIndexingConcept: nonIndexingConcept,
      FacetNode: facetNode,
      HierarchyNode: hierarchyNode,
      ThesaurusArray: thesaurusArray,
    },
  ],
  isMemberOf: thesaurusArray,
  narrower: narrower,
});
