import { NodesMap } from '../features/Tree';
import {
  normalizeData,
  NormalizerResponse,
  XTreeJsonResponse,
} from '../model/normalizer';
import { initNodesMapFromNormalizedResponse } from '../model/tree/normalizerToNodeUtils';
import { ErrorBuilder } from './errors';

export type ParsedXtreeResponse = { error?: Error; nodesMap?: NodesMap };

export function parseXtreeResponse(
  response: XTreeJsonResponse,
): ParsedXtreeResponse {
  if (!response) {
    throw ErrorBuilder.noDataFound();
  }

  if (response.vocItemCount === 0) {
    return { nodesMap: {} };
  }

  let normalized: NormalizerResponse | null = null;
  try {
    normalized = normalizeData(response);
  } catch (error) {
    throw error;
  }

  if (!normalized) {
    throw ErrorBuilder.couldNotProcess();
  }

  const nodesMap: NodesMap = initNodesMapFromNormalizedResponse(normalized);

  return { nodesMap };
}
