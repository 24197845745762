import { createSlice } from 'utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { ConfigState } from './types';

const staticConfig: any = window['appConfig'];

export const initialState: ConfigState = {
  xTreeApi: {
    url: staticConfig.apiUrl || '',
    vocabulary: staticConfig.xTreeVocabulary || '',
    username: staticConfig.xTreeApiUsername || '',
    password: staticConfig.xTreeApiPassword || '',
    formLoginName: staticConfig.xTreeApiFormLoginName || '',
    formLoginId: staticConfig.xTreeApiFormLoginId || '',
  },
  matomo: {
    url: staticConfig?.matomoUrl || '',
    siteId: staticConfig?.matomoSiteId || '',
  },
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
});

// Exports
export const { name: sliceKey } = configSlice;
export default configSlice.reducer;
