import { TreeNode } from '../../api/xtree';
import { NodesMap } from '../../features/Tree';
import { NormalizerResponse, NormalizerResult } from '../normalizer';

export function normalizedItemToTreeNode(
  resultItem: NormalizerResult,
  normalizedData: NormalizerResponse,
): TreeNode {
  const { vocabItem, entityType } = getVocabItem(resultItem, normalizedData);
  return vocabItemToTreeNode(vocabItem, entityType, normalizedData);
}

export function vocabItemToTreeNode(
  vocabItem,
  entityType,
  normalizedData,
): TreeNode {
  const node: TreeNode = {
    id: vocabItem.id,
    entityType,
    preferredTerm: vocabItem.preferredTerm,
    qualifier: vocabItem.qualifier || '',
    lang: getLang(vocabItem, normalizedData),
    parentIds: vocabItem.superordinateRelation
      ? vocabItem.superordinateRelation.map(r => r.id)
      : [],
    childrenIds: vocabItem.subordinateRelation
      ? vocabItem.subordinateRelation.map(r => r.id)
      : [],
    superordinateRelation: vocabItem.superordinateRelation ?? [],
    subordinateRelation: vocabItem.subordinateRelation ?? [],
  };
  return node;
}

export function getVocabItem(
  resultItem: NormalizerResult,
  normalizedData: NormalizerResponse,
) {
  const entityType: string = Object.keys(resultItem)[0];
  const id = resultItem[entityType] as any;
  const vocabItem = normalizedData.entities[entityType][id];
  return { vocabItem, entityType };
}

export function getLang(vocabItem, normalizedData) {
  let lang: string = '';
  if (normalizedData.entities.term) {
    lang = normalizedData.entities.term[vocabItem.preferredTermId].lang;
  }
  return lang;
}

export function getIds(list, normalizedData) {
  return list.map(item => {
    const { vocabItem } = getVocabItem(item, normalizedData);
    return vocabItem ? vocabItem.id : null;
  });
}

export function initNodesMapFromNormalizedResponse(
  normalizedData: NormalizerResponse,
): NodesMap {
  const nodesMap: NodesMap = {};
  normalizedData.result.forEach((result: NormalizerResult) => {
    const node: TreeNode = normalizedItemToTreeNode(result, normalizedData);
    nodesMap[node.id] = node;
  });
  return nodesMap;
}
