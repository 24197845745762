/*
 * Media queries utility
 */

import { css } from 'styled-components/macro';
import { defaultTheme } from './defaultTheme';

export const { breakpoints } = defaultTheme;
// mobile: 414px;
// tablet_portrait: 768;
// tablet_landscape: 1024;
// desktop: 1280;

export const media = {
  mobile_tiny: (...args) => css`
    @media only screen and (max-width: ${breakpoints.mobile - 1}px) {
      ${css(...args)}
    }
  `,
  mobile: (...args) => css`
    @media only screen and (max-width: ${breakpoints.tablet_portrait - 1}px) {
      ${css(...args)}
    }
  `,
  mobile_up: (...args) => css`
    @media only screen and (min-width: ${breakpoints.mobile}px) {
      ${css(...args)}
    }
  `,
  tablet_portrait_up: (...args) => css`
    @media only screen and (min-width: ${breakpoints.tablet_portrait}px) {
      ${css(...args)}
    }
  `,
  tablet_landscape_up: (...args) => css`
    @media only screen and (min-width: ${breakpoints.tablet_landscape}px) {
      ${css(...args)}
    }
  `,
  desktop_up: (...args) => css`
    @media only screen and (min-width: ${defaultTheme.breakpoints.desktop}px) {
      ${css(...args)}
    }
  `,
};
