import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { GlobalStyle, defaultTheme } from '../styles';
import { routes, RouteConfig } from './routes';

function App() {
  const { i18n, t } = useTranslation('helmet');
  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet
        titleTemplate={t('helmet.default.title.template')}
        defaultTitle={t('helmet.default.title')}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={t('helmet.default.description')} />
      </Helmet>
      <Switch>
        {routes.map((route: RouteConfig) => (
          <Route {...route} />
        ))}
      </Switch>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
