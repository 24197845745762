import { current, PayloadAction } from '@reduxjs/toolkit';
import { useInjectSaga } from 'redux-injectors';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux-injectors';
import { NodeInspector } from '../../model/tree/NodeInspector';
import { actions as searchActions } from '../Search';
import { treeSaga } from './sagas/treeSaga';
import { NodesMap, TreeState } from './types';

const ROOT_ID = NodeInspector.getRootNode();
export const initialState: TreeState = {
  isLoading: false,
  error: null,
  nodesMap: {},
  requestedIds: [],
  expandedNodes: [ROOT_ID],
  selectedId: null,
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    toggleNode(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const expandedNodes = current(state.expandedNodes);
      const nodesMap = current(state.nodesMap);
      if (expandedNodes.includes(id)) {
        const children = NodeInspector.getAllChildrenIds(id, nodesMap);
        state.expandedNodes = expandedNodes.filter(n => {
          return n !== id && !children.includes(n);
        });
      } else {
        state.expandedNodes.push(id);
      }
    },
    loadTree(
      state,
      action: PayloadAction<{ id?: string; missingIds?: string[] }>,
    ) {
      state.isLoading = true;
      state.error = null;
    },
    loadTreeSuccess(state, action: PayloadAction<{ nodesMap?: NodesMap }>) {
      state.isLoading = false;
      state.nodesMap = {};
      if (action.payload.nodesMap) {
        state.nodesMap = action.payload.nodesMap;
        state.requestedIds = Object.keys(action.payload.nodesMap);
      }
    },
    loadTreeError(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.error = action.payload.error;
    },

    loadChildren(state, action: PayloadAction<{ id?: string }>) {
      state.isLoading = true;
    },

    selectNode(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.selectedId = id;

      const nodesMap = current(state.nodesMap);
      const expandedNodes = current(state.expandedNodes);

      state.expandedNodes = addToListNoDuplicates(
        NodeInspector.getAllParentIds(id, nodesMap),
        expandedNodes,
      );
    },
    reset(state) {
      state.expandedNodes = initialState.expandedNodes;
      state.isLoading = false;
      state.error = null;
    },
    loadIdsStandard(state, action: PayloadAction<{ ids: string[] }>) {},
    loadIdsStandardSuccess(state, action: PayloadAction<{ nodes: NodesMap }>) {
      if (action.payload.nodes) {
        const nodesMap = current(state.nodesMap);
        const nodes = { ...nodesMap, ...action.payload.nodes };
        state.nodesMap = nodes;
      }
    },
    loadIdsStandardError(state) {},
    resetSelectedId(state) {
      state.selectedId = '';
    },
  },
  extraReducers: builder =>
    builder.addCase(searchActions.search, (state, action) => {
      if (!action.payload.id) {
        state.selectedId = '';
      }
    }),
});

function addToListNoDuplicates(newItems: string[], list: string[]) {
  return [...list, ...newItems.filter((id: string) => !list.includes(id))];
}

// Exports
export const { actions } = treeSlice;
export const useTreeSlice = () => {
  useInjectReducer({ key: treeSlice.name, reducer: treeSlice.reducer });
  useInjectSaga({ key: treeSlice.name, saga: treeSaga });
  return { actions: treeSlice.actions };
};
