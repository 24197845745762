import { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  AboutPage,
  AccessibilityPage,
  DatenschutzPage,
  HelpPage,
  HomePage,
  ImpressumPage,
  NotFoundPage,
  ResultPage,
  SearchPage,
  TeamPage,
} from '../pages/Loadable';
import { Route, Routes } from './types';

export interface RouteConfig {
  key: keyof typeof Routes;
  path: Route;
  exact?: boolean;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

// order here is important!
export const routes: RouteConfig[] = [
  { key: 'Home', path: Routes.Home, exact: true, component: HomePage },
  { key: 'About', path: Routes.About, exact: true, component: AboutPage },
  { key: 'Team', path: Routes.Team, exact: true, component: TeamPage },
  { key: 'Help', path: Routes.Help, exact: true, component: HelpPage },
  {
    key: 'Datenschutz',
    path: Routes.Datenschutz,
    exact: true,
    component: DatenschutzPage,
  },
  {
    key: 'Accessibility',
    path: Routes.Accessibility,
    exact: true,
    component: AccessibilityPage,
  },
  {
    key: 'Impressum',
    path: Routes.Impressum,
    exact: true,
    component: ImpressumPage,
  },
  { key: 'Search', path: Routes.Search, exact: true, component: SearchPage },
  // non-exact routes
  { key: 'Result', path: Routes.Result, component: ResultPage },
  { key: 'fallback', path: Routes.fallback, component: NotFoundPage },
];
