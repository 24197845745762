import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectTree = (state: RootState) => state.tree || initialState;

export const selectIsTreeLoading = createSelector(
  [selectTree],
  substate => substate.isLoading,
);

export const selectNodesMap = createSelector(
  [selectTree],
  substate => substate.nodesMap,
);

export const selectNodeById = (id: string) =>
  createSelector([selectTree], substate => substate.nodesMap[id]);

export const selectIsNodeExpanded = (id: string) =>
  createSelector([selectTree], substate => substate.expandedNodes.includes(id));

export const selectSelectedId = createSelector(
  [selectTree],
  substate => substate.selectedId || '',
);

export const selectRequestedIds = createSelector(
  [selectTree],
  substate => substate.requestedIds,
);

export const selectError = createSelector(
  [selectTree],
  substate => substate.error,
);
