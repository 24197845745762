import styled from 'styled-components/macro';
import { media } from '../../../styles';

export const Grid = styled.div`
  display: -ms-grid;
  display: grid;

  grid-template-rows: auto;
  -ms-grid-rows: auto;

  grid-column-gap: ${props => props.theme.grid.gutter.mobile}rem;

  ${media.tablet_portrait_up`
    grid-column-gap: ${props => props.theme.grid.gutter.tablet_portrait}rem;
  `};

  ${media.desktop_up`
    grid-column-gap: ${props => props.theme.grid.gutter.desktop}rem;
  `};
`;

export const OneToTwoGrid = styled(Grid)`
  min-height: 100%;

  grid-template-rows: auto 1fr;
  -ms-grid-rows: auto 1fr;

  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;

  grid-template-areas:
    'left'
    'right';

  ${media.desktop_up`
    grid-template-rows: auto;
    -ms-grid-rows: auto;

    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-column-gap: 0;

    grid-template-areas:
    'left right';
  `};
`;
