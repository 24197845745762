import React from 'react';
import { PageHeader } from '../PageHeader';
import { PageFooter } from '../PageFooter';
import { Loader } from '../Loader';
import { PageGrid, PageMain } from './style';

export function PageLayout(props) {
  return (
    <PageGrid>
      <PageHeader />
      <PageMain id="main-content">{props.children}</PageMain>
      <PageFooter />
      <Loader />
    </PageGrid>
  );
}
