import React from 'react';
import { useSelector } from 'react-redux';
import { selectSearchIsLoading } from '../../features/Search';
import { selectIsTreeLoading } from '../../features/Tree';
import { IconLoader } from '../Icon';
import { LoaderWrapper } from './style';

export function Loader() {
  const isSearchLoading = useSelector(selectSearchIsLoading);
  const isTreeLoading = useSelector(selectIsTreeLoading);

  if (!isSearchLoading && !isTreeLoading) {
    return null;
  }

  return (
    <LoaderWrapper $isPartOnly={false}>
      <IconLoader aria-hidden={true} />
    </LoaderWrapper>
  );
}
