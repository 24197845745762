import { current, PayloadAction } from '@reduxjs/toolkit';
import { useInjectSaga } from 'redux-injectors';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux-injectors';
import { mergeDataAsMap } from '../../model/tree/utils';
import { searchSaga } from './sagas/searchSaga';
import { SearchState, SearchRequest, SearchResponse } from './types';

export const initialState: SearchState = {
  isLoading: false,
  request: {
    term: undefined,
    id: undefined,
  },
  response: undefined,
  data: {
    idsList: [],
    mapById: {},
  },
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search(state, action: PayloadAction<SearchRequest>) {
      state.isLoading = true;
      state.request.term = action.payload.term;
      state.request.id = action.payload.id;
      state.data = initialState.data;
    },
    searchSuccess(state, action: PayloadAction<SearchResponse>) {
      state.isLoading = false;
      state.response = action.payload;

      state.data.idsList = action.payload.normalizedData.result.map(
        item => item[Object.keys(item)[0]],
      );
      state.data.mapById = mergeDataAsMap(
        current(state.data.mapById),
        action.payload.normalizedData,
      );
    },
    searchError(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
    },
  },
});

// Exports
export const { actions } = searchSlice;
export const useSearchSlice = () => {
  useInjectReducer({ key: searchSlice.name, reducer: searchSlice.reducer });
  useInjectSaga({ key: searchSlice.name, saga: searchSaga });
  return { actions: searchSlice.actions };
};
