/** XTree parameters */
export const XTreeParams = {
  Direction: {
    up: 'up',
    down: 'down',
  },
  JsonFull: {
    standard: '0',
    full: '1',
    technic: '2',
    custom: '4711',
    termSort: '17',
  },
  Level: {
    one: '1',
    N: 'N',
  },
  Mode: {
    and: 'AND',
    or: 'OR',
  },
  SearchFields: {
    all: 'all',
    alt: 'alt',
    hidden: 'hidden',
    pref: 'pref',
    quasiSynonym: 'quasi-synonym',
    useBroader: 'useBroader',
    useComb: 'useComb',
  },
  VocItemType: {
    all: 'all',
    onlyFacetNodes: 'concept__non_indexing__facet_node',
    onlyHierarchyNodes: 'concept__non_indexing__hierarchy_node',
    // ...
  },
};
