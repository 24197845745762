import { css } from 'styled-components/macro';
import { media } from './media';

/**
 * ---------------------------------------------------------------
 * The fonts as defined in the styleguide.
 *
 * For easier maintenance, ensure names match exactly the ones in
 * the styleguide.
 * ---------------------------------------------------------------
 */

export const font_h1 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._35}rem;
  line-height: ${40.07 / 35};
  color: ${props => props.theme.color.black};
`;

export const font_H2 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._24}rem;
  line-height: ${28 / 24};
  color: ${props => props.theme.color.black};

  ${media.desktop_up`
    font-size: ${props => props.theme.font.size._28}rem;
    line-height: ${32 / 28};
  `};
`;
export const font_H3 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._21}rem;
  line-height: ${24 / 21};
  color: ${props => props.theme.color.dark_blue};
`;

export const font_body = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${22 / 16};
  color: ${props => props.theme.color.black};
`;

export const font_body_bold = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${22 / 16};
  color: ${props => props.theme.color.black};
`;

export const font_body_light = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.light};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${20 / 16};
  color: ${props => props.theme.color.black};
`;

export const font_body_white = css`
  ${font_body};
  color: ${props => props.theme.color.white};
`;

export const font_body_bold_gray = css`
  ${font_body_bold};
  color: ${props => props.theme.color.medium_gray};
`;

export const font_header = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._21}rem;
  line-height: ${24.61 / 21};
  color: ${props => props.theme.color.white};
`;

export const font_footer = font_body_white;

export const font_link = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${19 / 16};
  color: ${props => props.theme.color.black};
  text-decoration: underline;
`;

export const font_link_extern = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  color: ${props => props.theme.color.link_blue};
`;
export const font_highlighted_term = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${19 / 16};
  color: ${props => props.theme.color.white};
`;

export const font_tree_item = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._14}rem;
  line-height: ${16 / 14};
  color: ${props => props.theme.color.black};

  ${media.tablet_portrait_up`
    font-size: ${props => props.theme.font.size._16}rem;
    line-height: ${19 / 16};
  `}
`;

export const font_tree_item_focused = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${19 / 16};
  color: ${props => props.theme.color.white};
`;

export const font_suggested_term = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._20}rem;
  line-height: ${30 / 20};
  color: ${props => props.theme.color.black};
  text-decoration: underline;
`;

export const font_result_link = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.light};
  font-size: ${props => props.theme.font.size._26}rem;
  line-height: ${35 / 26};
  color: ${props => props.theme.color.black};

  ${media.tablet_portrait_up`
    color: ${props => props.theme.color.white};
  `}
`;

export const font_total_results = css`
  ${font_body};

  ${media.tablet_portrait_up`
    color: ${props => props.theme.color.white};
  `}
`;

export const font_teaser = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._20}rem;
  line-height: ${23 / 20};
  color: ${props => props.theme.color.dark_blue};
`;

export const font_body_buch = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${22 / 16};
`;

export const font_body_book_bold = css`
  ${font_body_buch};
  font-weight: ${props => props.theme.font.weight.bold};
`;

export const font_results_cta = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.light};
  font-size: ${props => props.theme.font.size._20}rem;
  line-height: ${25 / 20};
  color: ${props => props.theme.color.black};
`;

export const font_results_cta_link = css`
  ${font_results_cta};
  font-weight: ${props => props.theme.font.weight.bold};
`;

export const font_caption = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.light};
  font-size: ${props => props.theme.font.size._14}rem;
  line-height: ${16 / 14};
  color: ${props => props.theme.color.black};
`;
