import { useSelector } from 'react-redux';
import { SearchResponse, selectSearchResponse } from '../features/Search';
import { selectSelectedId } from '../features/Tree';
import {
  NormalizerEntities,
  NormalizerResult,
  VocabType,
} from '../model/normalizer';
import { VocabularyItemFactory } from '../model/vocabularyItems/VocabularyItemFactory';

export function useSelectedVocab() {
  const searchResponse: SearchResponse | undefined = useSelector(
    selectSearchResponse,
  );
  const currentId: string = useSelector(selectSelectedId);
  if (!searchResponse || !currentId) {
    return null;
  }

  const result: NormalizerResult = searchResponse.normalizedData.result[0];
  if (!result) {
    return null;
  }
  const type: VocabType = Object.keys(result)[0] as VocabType;
  const entities: NormalizerEntities = searchResponse.normalizedData.entities;
  const item = entities[type][currentId];
  if (!item) {
    return null;
  }

  return VocabularyItemFactory.create(item, type, entities);
}
