import styled from 'styled-components/macro';
import {
  font_Body,
  font_BodyBold,
  font_BodyLight,
  font_H1,
  font_H2,
  font_H3,
  media,
} from '../../../styles';

export const H1 = styled.h1`
  ${font_H1};
  margin: 0 auto ${props => props.theme.spacing._11}rem;

  ${media.desktop_up`
    margin: 0 auto ${props => props.theme.spacing._16}rem;
  `}
`;

export const H2 = styled.h2`
  ${font_H2};
  margin: 0 auto;
`;

export const H3 = styled.h3`
  ${font_H3};
  margin: 0 auto;
`;

export const P = styled.p`
  display: block;
  ${font_Body};
  margin-top: 0;
`;
export const PLight = styled.p`
  display: block;
  ${font_BodyLight};
  margin-top: 0;
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

export const LI = styled.li`
  list-style: none;
`;

export const HiddenH1 = styled.h1`
  margin: 0;
`;
export const HiddenH2 = styled.h2`
  margin: 0;
`;

export const SectionHeader = styled.h2`
  ${font_BodyBold};
  margin: 0;
`;
