import { useHistory } from 'react-router-dom';
import { KeyboardKeyCodes } from '../../../utils/KeyboardKeyCodes';
import { RouteUtils } from '../../routes';

export function useSearchBarHandlers(state) {
  const history = useHistory();

  const submitSearchTerm = () => {
    history.push(RouteUtils.getSearchTermLink(state.searchTerm));
  };

  const onChange = evt => {
    state.setSearchTerm(evt.target.value);
  };

  const onBlur = () => {
    state.setFocus(false);
  };

  const onFocus = () => {
    state.setFocus(true);
  };

  const onKeyUp = evt => {
    if (evt.which === KeyboardKeyCodes.ENTER) {
      submitSearchTerm();
    }
  };

  const resetTerm = () => {
    state.setSearchTerm('');
  };

  const doEmptySearch = () => {
    state.setSearchTerm('');
    history.push(RouteUtils.getSearchTermLink(''));
  };

  return {
    onBlur,
    onChange,
    onFocus,
    onKeyUp,
    doEmptySearch,
    resetTerm,
    submitSearchTerm,
  };
}
