import { put } from 'redux-saga/effects';

export enum ErrorMessages {
  No_data_found = 'No data found',
  Could_not_be_processed = 'Data could not be processed',
  Unknown_error = 'Error',
}

export type ErrorMessage =
  | ErrorMessages.Could_not_be_processed
  | ErrorMessages.No_data_found
  | ErrorMessages.Unknown_error;

export class ErrorBuilder {
  public static build(message: ErrorMessage): { error: Error } {
    return { error: new Error(message) };
  }

  public static couldNotProcess() {
    return ErrorBuilder.build(ErrorMessages.Could_not_be_processed);
  }

  public static noDataFound() {
    return ErrorBuilder.build(ErrorMessages.No_data_found);
  }

  public static unknown() {
    return ErrorBuilder.build(ErrorMessages.Unknown_error);
  }

  public static error(e) {
    return e.hasOwnProperty('error') ? e : { error: e };
  }
}

export function* errorHandler(e: { error: Error }, action, cb) {
  console.log('ERROR', { action, e });
  yield put(cb({ error: e.error.message }));
}
