import slugify from 'slugify';
import { Routes } from './types';

export class PermalinkBuilder {
  public static build(id: string) {
    return Routes.Result.replace(':id', id);
  }

  public static buildURI(id: string) {
    return `http://lvr.vocnet.org/wnk/${id}`;
  }
}

export function toSlug(text: string): string {
  return slugify(replaceGermanCharacters(text));
}

function replaceGermanCharacters(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss');
}
