import { _t } from '../../../utils/messages';

const scope = 'Menu.FooterNavigation';

export const messages = {
  help: _t(`${scope}.help`),
  impressum: _t(`${scope}.impressum`),
  datenschutz: _t(`${scope}.datenschutz`),
  licence: _t(`${scope}.licence`),
  accessibility: _t(`${scope}.accessibility`),
  ccLogoAlt: _t(`${scope}.cc.logo.ariaLabel`),
  ccLogoUrl: _t(`${scope}.cc.logo.url`),
};
