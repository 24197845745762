/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { InjectedReducersType } from 'utils/types/injector-typings';
import { history } from './history';
import configReducer, { sliceKey as configKey } from '../app/features/Config';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    ...injectedReducers,
    // other non-injected reducers can go here...
    router: connectRouter(history),
    [configKey]: configReducer,
  });
}
