import { ResourceRepresentation } from '../normalizer';
import { VocabImage } from './VocabImage';

export class VocabImages {
  protected _images: VocabImage[] = [];

  public constructor(data: ResourceRepresentation[]) {
    if (!data) return;
    this._images = data
      .map((item: ResourceRepresentation) => new VocabImage(item))
      .filter(image => image.hasImageUrl);
  }

  get images() {
    return this._images;
  }
}
