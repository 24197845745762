export enum Routes {
  About = '/about',
  Help = '/help',
  Home = '/',
  Impressum = '/impressum',
  Datenschutz = '/datenschutz',
  Result = '/wnk/:id',
  Search = '/search',
  Team = '/team',
  fallback = '/*',
  Accessibility = '/barrierefreiheit',
}

export type Route =
  | Routes.About
  | Routes.Datenschutz
  | Routes.fallback
  | Routes.Help
  | Routes.Home
  | Routes.Impressum
  | Routes.Result
  | Routes.Search
  | Routes.Team
  | Routes.Accessibility;
