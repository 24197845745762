import { normalize } from 'normalizr';
import { NormalizerResponse } from './types';
import { vocabularyItemSchema } from './schema';

export const emptySearchResponse: NormalizerResponse = {
  entities: {},
  result: [],
};

export function normalizeData(json) {
  if (!json.vocItemCount || json.vocItemCount === 0) {
    return emptySearchResponse;
  }

  try {
    return normalize(
      json.VocabularyItem,
      vocabularyItemSchema,
    ) as NormalizerResponse;
  } catch (e) {
    console.log('Error normalizing data', e);
    return emptySearchResponse;
  }
}
