import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectMobileMenu = (state: RootState) => state.mobileMenu || initialState;

export const selectIsMobileMenuOpen = createSelector(
  [selectMobileMenu],
  substate => substate.isOpen,
);
