import { call, put, select } from 'redux-saga/effects';
import xTreeApi, {
  ErrorBuilder,
  errorHandler,
  ErrorMessages,
  parseXtreeResponse,
} from '../../../api';
import { XTreeParams } from '../../../api/xtree';
import {
  createHierarchy,
  filterHierarchyIds,
} from '../../../model/hierarchy/hierarchyBuilder';
import { XTreeJsonResponse } from '../../../model/normalizer';
import { selectNodesMap } from '../selectors';
import { actions } from '../slice';
import { NodesMap } from '../types';

export function* loadTreeBottomUp(action) {
  let nodesMap: NodesMap = yield select(selectNodesMap);
  const { id, missingIds } = action.payload;

  try {
    const nodesFromIdToRoot = yield call(fetchHierarchyFromIdToRoot, id);
    if (Object.keys(nodesFromIdToRoot).length === 0) {
      throw new Error(ErrorMessages.No_data_found);
    }

    nodesMap = { ...nodesFromIdToRoot };

    const nodesOfCurrentAndMissingIds = yield call(
      fetchIds,
      [id].concat(missingIds ?? []),
    );
    if (Object.keys(nodesOfCurrentAndMissingIds).length === 0) {
      throw new Error(ErrorMessages.No_data_found);
    }

    nodesMap = { ...nodesMap, ...nodesOfCurrentAndMissingIds };

    const childrenOfHierarchyNodes = yield call(
      fetchChildrenOfNodesInHierarchy,
      id,
      nodesMap,
    );
    nodesMap = { ...nodesMap, ...childrenOfHierarchyNodes };
  } catch (e) {
    yield call(
      errorHandler,
      ErrorBuilder.error(e),
      action,
      actions.loadTreeError,
    );
  }

  yield put(actions.loadTreeSuccess({ nodesMap }));
  yield put(actions.selectNode({ id }));
}

function* fetchHierarchyFromIdToRoot(id: string) {
  const options = {
    direction: xTreeApi.XTreeParams.Direction.up,
    jsonFull: xTreeApi.XTreeParams.JsonFull.custom,
    level: xTreeApi.XTreeParams.Level.N,
    nodeid: id,
  };
  const response: XTreeJsonResponse = yield call(
    xTreeApi.getFetchHierarchy,
    options,
  );
  const dataFetchHierarchy = parseXtreeResponse(response);
  if (dataFetchHierarchy.error) {
    throw ErrorBuilder.error(dataFetchHierarchy);
  }
  return dataFetchHierarchy.nodesMap;
}

function* fetchIds(ids: string[]) {
  const response: XTreeJsonResponse = yield call(
    xTreeApi.getSearchVocItemsById,
    ids,
    XTreeParams.JsonFull.custom,
  );
  const parsedResponse = parseXtreeResponse(response);
  return parsedResponse.nodesMap;
}

function* fetchChildrenOfNodesInHierarchy(id: string, nodesMap: NodesMap) {
  const branchRoots: string[] = getExpandedNodeIds(id, nodesMap);

  let idsToFetch: string[] = getChildIdsThatShouldBeRequested(
    branchRoots,
    nodesMap,
  );

  if (idsToFetch.length > 0) {
    const response: XTreeJsonResponse = yield call(
      xTreeApi.getSearchVocItemsById,
      idsToFetch,
      XTreeParams.JsonFull.custom,
    );
    if (!response.hasOwnProperty('vocItemCount')) {
      throw ErrorBuilder.couldNotProcess();
    }
    const dataFetchFurtherIds = parseXtreeResponse(response);
    return dataFetchFurtherIds.nodesMap;
  }
  return {};
}

function getExpandedNodeIds(id: string, nodesMap: NodesMap): string[] {
  const hierarchy: string[] | null = createHierarchy(id, nodesMap);
  let pathsList: string[] = [];
  if (!!hierarchy) {
    // NOTE on API response: Filtering out only generic and partitive items
    // doesn't work for "Anlage nach Funktion"
    const superOrdinateIds =
      nodesMap[id].superordinateRelation?.map(item => item.id) ?? [];
    pathsList = [...filterHierarchyIds(id, hierarchy, superOrdinateIds)];
  }

  return pathsList.reduce((acc: string[], path: string) => {
    const ids = path.split(';');
    ids.forEach(i => {
      if (!!i && !acc.includes(i)) acc.push(i);
    });
    return acc;
  }, []);
}

function getChildIdsThatShouldBeRequested(
  branchRoots: string[],
  nodesMap: NodesMap,
): string[] {
  let list: string[] = [];
  branchRoots.forEach(id => {
    if (
      !nodesMap.hasOwnProperty(id) ||
      !nodesMap[id].hasOwnProperty('subordinateRelation')
    ) {
      list = [...list, id];
    } else {
      list = [
        ...list,
        ...nodesMap[id].childrenIds.filter(cid => {
          return (
            !nodesMap.hasOwnProperty(cid) ||
            !nodesMap[cid].hasOwnProperty('superordinateRelation')
          );
        }),
      ];
    }
  });

  return list;
}
