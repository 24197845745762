import { call, takeLatest } from 'redux-saga/effects';
import { actions } from '../slice';
import { loadIdsStandard } from './loadIdsStandard';
import { loadRoots } from './loadRoots';
import { loadTreeBottomUp } from './loadTreeBottomUp';
import { loadTreeLevelTopDown } from './loadTreeLevelTopDown';

export function* treeSaga() {
  yield takeLatest(actions.loadTree.type, loadTree);
  yield takeLatest(actions.loadChildren.type, loadTreeLevelTopDown);
  yield takeLatest(actions.loadIdsStandard.type, loadIdsStandard);
}

export function* loadTree(action) {
  if (action.payload.id) {
    yield call(loadTreeBottomUp, action);
  } else {
    yield call(loadRoots, action);
  }
}
