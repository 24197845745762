export enum ContentViewType {
  Details = 'details',
  SearchResults = 'searchResults',
  Tree = 'tree',
}

export interface ContentViewSwitcherState {
  readonly view:
    | ContentViewType.Details
    | ContentViewType.SearchResults
    | ContentViewType.Tree;
}
