import { call } from 'redux-saga/effects';
import { buildRequestQuery, defaultOptions } from '../buildRequestQuery';
import { buildRequestUrl } from '../buildRequestUrl';
import { ENDPOINT } from '../constants';
import { ErrorBuilder } from '../errors';
import { authenticatedRequest } from './login';
import { validateXTreeResponse } from './validateXTreeResponse';

export function* getFetchHierarchy(options) {
  const query = yield call(buildRequestQuery, {
    ...defaultOptions,
    ...options,
  });
  const url = yield call(buildRequestUrl, ENDPOINT.Hierarchy, query);
  const response = yield call(authenticatedRequest, url);

  if (!validateXTreeResponse(response)) {
    throw ErrorBuilder.couldNotProcess();
  }
  return response;
}
