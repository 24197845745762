import { call } from 'redux-saga/effects';
import { buildRequestQuery } from '../buildRequestQuery';
import { buildRequestUrl } from '../buildRequestUrl';
import { ENDPOINT } from '../constants';
import { ErrorBuilder } from '../errors';
import { XTreeParams } from './constants';
import { authenticatedRequest } from './login';
import { XTreeQueryParams } from './types';
import { validateXTreeResponse } from './validateXTreeResponse';

export function* getSearchVocItemsById(
  ids: string[],
  jsonFull: string = XTreeParams.JsonFull.full,
) {
  const options: XTreeQueryParams = {
    searchIdsList: ids.join('+'),
    jsonFull,
    public: 1,
  };
  const query = yield buildRequestQuery(options);
  const url = yield call(buildRequestUrl, ENDPOINT.SearchById, query);
  const response = yield call(authenticatedRequest, url);

  if (!validateXTreeResponse(response)) {
    throw ErrorBuilder.couldNotProcess();
  }
  return response;
}
